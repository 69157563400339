export default {
  SET_IMAGES: (state, images) => {
    state.images = images.map((el) => {
      return el;
    });
  },
  SET_IMAGES_BY_KEYWORD: (state, imagesByKeywords) => {
    state.imagesByKeywords = imagesByKeywords;
  },
  RESET_IMAGES_BY_KEYWORDS: (state) => {
    state.imagesByKeywords = [];
  },
  RESET: (state) => {
    state.read = {};
  },
  SET_IS_LOADING: (state, isLoading) => {
    state.isLoading = isLoading
  },
};
