import { apollo } from "../../../../plugins/vue-apollo";
import { CREATE_IMAGES } from "../graphql/mutation";
import { FIND_IMAGES, FIND_IMAGES_WITH_KEYWORDS } from "../graphql/query";
import { messagePopUp } from "../../../../utils/utils";
export default {
  /* 
		Note: use find images into stock images, addon module	
	*/
  FIND: async ({ commit }, { take, page }) => {
    commit('SET_IS_LOADING', true)
    await apollo
      .query({
        query: FIND_IMAGES,
        variables: { take, page },
      })
      .then(({ data: { images } }) => {
        commit("SET_IMAGES", images);

        commit('SET_IS_LOADING', false)
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  FIND_WITH_KEYWORDS: ({ commit }, { keywords }) => {
    apollo
      .query({
        query: FIND_IMAGES_WITH_KEYWORDS,
        variables: { keywords },
      })
      .then(({ data: { imagesByKeywords } }) => {
        commit("SET_IMAGES_BY_KEYWORD", imagesByKeywords);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  CREATE: ({ commit, dispatch }, { data, take, page }) => {
    apollo
      .mutate({
        mutation: CREATE_IMAGES,
        variables: {
          image: {
            name: data.name,
            mime_type: data.mime_type,
            width: data.width,
            height: data.height,
          },
          object: {
            account_id: data.account_id,
            type_user: data.type_user,
          },
        },
        update: (cache, { data: { image } }) => {
          const data = cache.readQuery({
            query: FIND_IMAGES,
            variables: { take, page },
          });
          data.images.unshift(image);

          cache.writeQuery({
            query: FIND_IMAGES,
            variables: { take, page },
            data: { images: data.images },
          });

          commit("SET_IMAGES", data.images);
        },
      })
      .then(() => {
        dispatch(
          "snackbar/show",
          {
            text: `Add success`,
            color: "success",
          },
          { root: true }
        );
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
};
