//import QRCode from "qrcode";
import draggable from "vuedraggable";
import CpBtnGray from "@/components/buttons/CpBtnGray.vue";
import InputCheckboxQuestion from "./questionComponents/InputCheckboxQuestion.vue";
import AnswerCheckbox from "./questionComponents/AnswerCheckbox.vue";
import AnswerRadioButton from "./questionComponents/AnswerRadioButton.vue";
import AnswerRange from "./questionComponents/AnswerRange.vue";
import AnswerText from "./questionComponents/AnswerText.vue";
import AnswerQrCode from "./questionComponents/AnswerQRCode.vue";
import AnswerSocialNetwork from "./questionComponents/AnswerSocialNetwork.vue";
import AnswerDate from "./questionComponents/AnswerDate.vue";
//import Swal from 'sweetalert2'
export default {
  name: "QuestionModule",
  components: {
    CpBtnGray,
    InputCheckboxQuestion,
    draggable,
    AnswerCheckbox,
    AnswerRadioButton,
    AnswerRange,
    AnswerText,
    AnswerQrCode,
    AnswerSocialNetwork,
    AnswerDate,
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },
    numberQuestion: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    textLink: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      typeQuestion: 0,
      question_select: 0,
      changeQr: false,
      datepicker: false,
      date: "",
      dateFormatted: "",
      checkbox: true,
      checkbox2: true,
      radios: "",
      onItemDrag: false,
      optionsDraggable: {
        filter: '.ignore-elements', 
        preventOnFilter: false,
      }
    };
  },
  computed: {
    questions: {
      get() {
        return this.$store.getters["addons/create/questions"];
      },
      set(value) {
        this.$store.commit("addons/create/SET_QUESTIONS", value);
      },
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    nameButton() {
      return this.$store.getters["addons/create/message"].button.name;
    },
  },
  watch: {
    numberQuestion(type) {
      this.createItemQuestion(type);
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    async textLink(val) {
      await this.generateQR(val);
    },
  },
  methods: {
    onStart(event){
      let box_drag = document.getElementById(`border-drag-${event.item.id}`);
      box_drag.style.display = 'block';
    },
    onEnd(event) {
      let box_drag = document.getElementById(`border-drag-${event.item.id}`);
      box_drag.style.display = 'none'
      this.element.question.map((item, key) => {
        item.position = key + 1;
        item.id = key + 1;
      });
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    createItemQuestion(type) {
      // let id = this.element.question
      //   ? this.element.question.length > 0
      //     ? parseInt(
      //         this.element.question[this.element.question.length - 1].id + 1
      //       )
      //     : 1
      //   : 1;

      const id = this.element.question.length + 1;

      const position = this.element.question.length + 1;

      let question = {};

      switch (type) {
        case 1:
          question = {
            id,
            type: type,
            other: false,
            required: true,
            question: "",
            answers: [],
            other_answer: "",
          };
          break;
        case 2:
          question = {
            id,
            type: type,
            required: true,
            question: "",
            answers: [],
          };
          break;
        case 3:
          question = {
            id,
            type: type,
            required: true,
            question: "",
            highrange: null,
            lowrange: null,
            reaction: {
              name: "stars",
              icon: "mdi-star",
            },
            rangeReaction: 1,
          };
          break;
        case 4:
          question = {
            id,
            type: type,
            required: true,
            question: "",
            answer: "",
          };
          break;
        case 5:
          question = {
            id,
            type: type,
            required: true,
            question: "",
            qr: {
              url: null,
              codeQr: null,
            },
          };
          break;
        case 6:
          question = {
            id,
            type: type,
            required: true,
            question: "",
            iFrames: [],
          };
          break;
        case 7:
          question = {
            id,
            type: type,
            required: true,
            question: "",
            date: "",
          };
          break;
      }
      question.position = position;
      this.$store.commit("addons/create/SET_ITEM_QUESTION", question);
      this.$store.dispatch('addons/changes/SAVE_CHANGES');
    },
    setSelect(id, index) {
      this.question_select = id;
      //this.question_select_index = index;
      this.$emit("selected-index", index); // emit
    },
    setHighRangeByItem(id_question, x) {
      this.element.question[id_question - 1].highrange = x;
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    setLowRangeByItem(id_question, x) {
      this.element.question[id_question - 1].lowrange = x;
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    /*
     * ANSWER SECTION
     */
    createAnswerId(id_question){
      let id = this.element.question[id_question - 1].answers.length > 0
      ? this.element.question[id_question - 1].answers[
          this.element.question[id_question - 1].answers.length - 1
        ].id + 1
      : 1;

      return id;
      
    },
    addAnswer(id_question, isCheckedType) {
      let answer = {};
      answer.id = this.createAnswerId(id_question);        
      if (isCheckedType) {
        answer.state = false;
      }
      answer.text = "";
      this.element.question[id_question - 1].answers.push(answer);
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);

      this.$store.dispatch('addons/changes/SAVE_CHANGES');
    },
    addIframe(id_question) {
      let iFrame = {};
      iFrame.id =
        this.element.question[id_question - 1].iFrames.length > 0
          ? this.element.question[id_question - 1].iFrames[
              this.element.question[id_question - 1].iFrames.length - 1
            ].id + 1
          : 1;
      iFrame.link = "";
      this.element.question[id_question - 1].iFrames.push(iFrame);
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    deleteAnswer(id_answer, id_question) {
      let el = this.element.question[id_question - 1];
      let find = el.answers
        .map((x) => x.id)
        .indexOf(id_answer);
      if(el.answers[find].other) {
        el.other_answer = "";
        el.other = false;
      }
      el.answers.splice(find, 1);
      if (this.element.question.length == 0) {
        this.question_select = 0;
        //this.question_select_index = -1;
        this.$emit("selected-index", -1);
      }
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    deleteIframe(questionItemId, questionId) {
      console.log(questionItemId, questionId);
      let find = this.element.question[questionItemId - 1].iFrames
        .map((x) => {
          return x.id;
        })
        .indexOf(questionId);
      this.element.question[questionItemId - 1].iFrames.splice(find, 1);
      if (this.element.question.length == 0) {
        this.question_select = 0;
        //this.question_select_index = -1;
        this.$emit("selected-index", -1);
      }
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    setTextAnswer(id_answer, id_question, text) {
      this.element.question[id_question - 1].answers[id_answer - 1].text = text;

      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    setAnswerByItem(id_question, text) {
      this.element.question[id_question - 1].answer = text;
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    setDateByItem(questionItemId, date) {
      this.$store.dispatch("addons/create/SET_NEW_DATE", {
        questionItemId,
        date,
      });
    },
    setIframeByItem(id_question, iframeId, link) {
      this.element.question[id_question - 1].iFrames[iframeId - 1].link = link;
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    /*
     * QUESTION SECTION
     */
    deleteQuestion(id_question) {
      let find = this.element.question
        .map((x) => {
          return x.id;
        })
        .indexOf(id_question);
      const removeQuestion = this.element.question.splice(find, 1);
      this.element.question.map((item, key) => {
        item.position = key + 1;
        item.id = key + 1;
      });
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);

      this.question_select = 0;
      //this.question_select_index = -1;
      this.$emit("selected-index", -1);
      if (this.element.addonId) this.deleteQuestionFromDba(removeQuestion);
    },
    setQuestionByItem(index, text) {
      this.element.question[index - 1].question = text;
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    deleteQuestionFromDba(removeQuestion) {
      const id = removeQuestion[0].idQuestionDb;
      if (id) this.$emit("id-question", id);
    },
    /*
     * SECTION DATE
     */
    setDate() {
      this.datepicker = !this.datepicker;
      //console.log(this.datepicker)
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    //* Save questions
    updateQuestions() {
      console.log(" button question...");
      /* Swal.fire({
				title: 'Oops...',
				text: 'It is required to save the project!',
				icon: 'error',
				showConfirmButton: false,
				timer: 1500,
			}); */
    },
  },
};
