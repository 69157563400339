const fontsFormatList = [
    { value: "0", text: "Arial", font: "Arial" },
    { value: "1", text: "Andada", font: "AndadaPro" },
    { value: "2", text: "Arial", font: "Arial" },
    { value: "3", text: "Antonio", font: "Antonio" },
    { value: "4", text: "Arapey", font: "Arapey" },
    { value: "5", text: "Archivo", font: "Archivo" },
    { value: "6", text: "Assistant", font: "Assistant" },
    { value: "7", text: "Barlow", font: "Barlow" },
    { value: "8", text: "Basic", font: "Basic" },
    { value: "9", text: "Be Vietnam", font: "BeVietnam" },
    { value: "10", text: "Bilbo", font: "Bilbo" },
    { value: "11", text: "Black Han Sans", font: "BlackHanSans" },
    { value: "12", text: "Blinker", font: "Blinker" },
    { value: "13", text: "Boogaloo", font: "Boogaloo" },
    { value: "14", text: "Bowlby One", font: "BowlbyOne" },
    { value: "15", text: "Bubbler One", font: "BubblerOne" },
    { value: "16", text: "Bungee", font: "Bungee" },
    { value: "17", text: "Bebas Neue", font: "BebasNeue" },
    { value: "18", text: "Economica", font: "Economica" },
    { value: "19", text: "Baskerville", font: "Baskerville" },
    { value: "20", text: "Seaweed Script", font: "SeaweedScript" },
    { value: "21", text: "Roboto", font: "Roboto" },
    { value: "22", text: "Phosphate", font: "Phosphate" },
    { value: "23", text: "Abhaya Libre", font: "AbhayaLibre" },
    { value: "24", text: "Creepster", font: "Creepster" },
    { value: "25", text: "KiloGram", font: "KiloGram" },
    { value: "26", text: "Langdon", font: "Langdon" },
    { value: "27", text: "LT Oksana", font: "LT-Oksana" },
    { value: "28", text: "LT Oksana Bold", font: "LT-Oksana-bold" },
    { value: "29", text: "LT Oksana Italic", font: "LT-Oksana-italic" },
    { value: "30", text: "LT Oksana Bold Italic", font: "LT-Oksana-bold-italic" },
    { value: "31", text: "Monoton", font: "Monoton" },
    { value: "32", text: "Pixelify Sans", font: "Pixelify-Sans" },
    { value: "32", text: "Pixelify Sans Medium", font: "Pixelify-Sans-medium" },
    { value: "33", text: "Pixelify Sans Bold", font: "Pixelify-Sans-bold" },
    { value: "34", text: "Pixelify Sans Semi Bold", font: "Pixelify-Sans-semi-bold" },
    { value: "35", text: "Reckoner", font: "Reckoner" },
    { value: "36", text: "Chunk Five", font: "ChunkFive" },
    { value: "37", text: "Ostrich Sans Inline", font: "Ostrich-Sans-Inline" },
    { value: "38", text: "Ostrich Sans Inline Italic", font: "Ostrich-Sans-Inline-italic" },
    { value: "39", text: "Ostrich Sans light", font: "Ostrich-Sans-light" },
    { value: "40", text: "Ostrich Sans Medium", font: "Ostrich-Sans-medium" },
    { value: "41", text: "Ostrich Sans Rounded Medium", font: "Ostrich-Sans-Rounded-medium" },
    { value: "42", text: "Ostrich Sans Black", font: "Ostrich-Sans-black" },
    { value: "43", text: "Ostrich Sans Bold", font: "Ostrich-Sans-bold" },
    { value: "44", text: "Ostrich Sans Heavy", font: "Ostrich-Sans-heavy" },
    { value: "45", text: "Ostrich Sans Dashed Medium", font: "Ostrich-Sans-Dashed-medium" },
    { value: "46", text: "Allison", font: "Allison" },
    { value: "47", text: "Architects Daughter", font: "Architects-Daughter" },
    { value: "48", text: "Cookie", font: "Cookie" },
    { value: "49", text: "Dancing Script", font: "Dancing-Script" },
    { value: "50", text: "Dancing Script Bold", font: "Dancing-Script-bold" },
    { value: "51", text: "Dancing Script Semi Bold", font: "Dancing-Script-semi-bold" },
    { value: "52", text: "Dancing Script Medium", font: "Dancing-Script-medium" },
    { value: "53", text: "Dela Gothic One", font: "Dela-Gothic-One" },
    { value: "54", text: "Lobster", font: "Lobster" },
    { value: "55", text: "Playwrite GB S", font: "PlaywriteGBS" },
    { value: "56", text: "Playwrite GB S Italic", font: "PlaywriteGBS-italic" },
    { value: "57", text: "Playwrite GB S Light", font: "PlaywriteGBS-light" },
    { value: "58", text: "Playwrite GB S Light Italic", font: "PlaywriteGBS-light-italic" },
    { value: "59", text: "Playwrite GB S Extra Light", font: "PlaywriteGBS-extra-light" },
    { value: "60", text: "Playwrite GB S Extra Light Italic", font: "PlaywriteGBS-extra-light-italic" },
    { value: "61", text: "Playwrite GB S Thin", font: "PlaywriteGBS-thin" },
    { value: "62", text: "Playwrite GB S Thin Italic", font: "PlaywriteGBS-thin-italic" },
    { value: "63", text: "Arsenica", font: "Arsenica-regular" },
    { value: "64", text: "Arsenica Bold", font: "Arsenica-bold" },
    { value: "65", text: "Arsenica Bold Italic", font: "Arsenica-bold-italic" },
    { value: "66", text: "Arsenica Semi Bold", font: "Arsenica-demi-bold" },
    { value: "67", text: "Arsenica Semi Bold Italic", font: "Arsenica-demi-bold-italic" },
    { value: "68", text: "Arsenica Extra Bold", font: "Arsenica-extra-bold" },
    { value: "69", text: "Arsenica Extra Bold Italic", font: "Arsenica-extra-bold-italic" },
    { value: "70", text: "Arsenica Light", font: "Arsenica-light" },
    { value: "71", text: "Arsenica Light Italic", font: "Arsenica-light-italic" },
    { value: "72", text: "Arsenica Medium", font: "Arsenica-medium" },
    { value: "73", text: "Arsenica Medium Italic", font: "Arsenica-medium-italic" },
    { value: "74", text: "Arsenica Thin", font: "Arsenica-thin" },
    { value: "75", text: "Arsenica Thin Italic", font: "Arsenica-thin-italic" },
    { value: "76", text: "Bevan", font: "Bevan" },
    { value: "77", text: "Bitter", font: "Bitter" },
    { value: "78", text: "Bitter Bold", font: "Bitter-bold" },
    { value: "79", text: "Bitter Bold Italic", font: "Bitter-bold-italic" },
    { value: "80", text: "Bitter Italic", font: "Bitter-italic" },
    { value: "81", text: "Bodoni Moda 9pt", font: "Bodoni-Moda-9pt" },
    { value: "82", text: "Bodoni Moda 9pt Bold", font: "Bodoni-Moda-9pt-bold" },
    { value: "83", text: "Bodoni Moda 9pt Bold Italic", font: "Bodoni-Moda-9pt-bold-italic" },
    { value: "84", text: "Bodoni Moda 9pt Extra Bold", font: "Bodoni-Moda-9pt-extra-bold" },
    { value: "85", text: "Bodoni Moda 9pt Extra Bold Italic", font: "Bodoni-Moda-9pt-extra-bold-italic" },
    { value: "86", text: "Bodoni Moda 9pt Black", font: "Bodoni-Moda-9pt-black" },
    { value: "87", text: "Bodoni Moda 9pt Black Italic", font: "Bodoni-Moda-9pt-black-italic" },
    { value: "88", text: "Bodoni Moda 9pt Italic", font: "Bodoni-Moda-9pt-italic" },
    { value: "89", text: "Bodoni Moda 9pt Medium", font: "Bodoni-Moda-9pt-medium" },
    { value: "90", text: "Bodoni Moda 9pt Medium Italic", font: "Bodoni-Moda-9pt-medium-italic" },
    { value: "91", text: "Bodoni Moda 9pt Semi Bold", font: "Bodoni-Moda-9pt-semi-bold" },
    { value: "92", text: "Bodoni Moda 9pt Semi Bold Italic", font: "Bodoni-Moda-9pt-semi-bold-italic" },
    { value: "93", text: "Bodoni Moda 18pt", font: "Bodoni-Moda-18pt-regular" },
    { value: "94", text: "Bodoni Moda 18pt Bold", font: "Bodoni-Moda-18pt-bold" },
    { value: "95", text: "Bodoni Moda 18pt Bold Italic", font: "Bodoni-Moda-18pt-bold-italic" },
    { value: "96", text: "Bodoni Moda 18pt Extra Bold", font: "Bodoni-Moda-18pt-extra-bold" },
    { value: "97", text: "Bodoni Moda 18pt Extra Bold Italic", font: "Bodoni-Moda-18pt-extra-bold-italic" },
    { value: "98", text: "Bodoni Moda 18pt Black", font: "Bodoni-Moda-18pt-black" },
    { value: "99", text: "Bodoni Moda 18pt Black Italic", font: "Bodoni-Moda-18pt-black-italic" },
    { value: "100", text: "Bodoni Moda 18pt Semi bold", font: "Bodoni-Moda-18pt-semi-bold" },
    { value: "101", text: "Bodoni Moda 18pt Semi bold Italic", font: "Bodoni-Moda-18pt-semi-bold-italic" },
    { value: "102", text: "Bodoni Moda 18pt Medium", font: "Bodoni-Moda-18pt-medium" },
    { value: "103", text: "Bodoni Moda 18pt Medium Italic", font: "Bodoni-Moda-18pt-medium-italic" },
    { value: "104", text: "Bodoni Moda 18pt Italic", font: "Bodoni-Moda-18pt-italic" },
    { value: "105", text: "Bodoni Moda 28pt Bold", font: "Bodoni-Moda-28pt-bold" },
    { value: "106", text: "Bodoni Moda 28pt Bold Italic", font: "Bodoni-Moda-28pt-bold-italic" },
    { value: "107", text: "Bodoni Moda 28pt Black", font: "Bodoni-Moda-28pt-black" },
    { value: "108", text: "Bodoni Moda 28pt Black Italic", font: "Bodoni-Moda-28pt-black-italic" },
    { value: "109", text: "Bodoni Moda 28pt Extra bold", font: "Bodoni-Moda-28pt-extra-bold" },
    { value: "110", text: "Bodoni Moda 28pt Extra bold Italic", font: "Bodoni-Moda-28pt-extra-bold-italic" },
    { value: "111", text: "DM Serif Text", font: "DM-Serif-Text" },
    { value: "112", text: "DM Serif Text Italic", font: "DM-Serif-Text-italic" },
    { value: "113", text: "Droid Serif", font: "Droid-Serif" },
    { value: "114", text: "Droid Serif Bold", font: "Droid-Serif-bold" },
    { value: "115", text: "Droid Serif Bold Italic", font: "Droid-Serif-bold-italic" },
    { value: "116", text: "Droid Serif Italic", font: "Droid-Serif-italic" },
    { value: "117", text: "Gaspar", font: "Gaspar" },
    { value: "118", text: "Gaspar Bold", font: "Gaspar-bold" },
    { value: "119", text: "Gaspar Bold Italic", font: "Gaspar-bold-italic" },
    { value: "120", text: "Gaspar Italic", font: "Gaspar-italic" },
    { value: "121", text: "Gilda Display", font: "Gilda-Display" },
    { value: "122", text: "Gran Design Neue Serif", font: "Grandesign-Neue-Serif" },
    { value: "123", text: "Gran Design Neue Serif Bold", font: "Grandesign-Neue-Serif-bold" },
    { value: "124", text: "Gran Design Neue Serif Bold Italic", font: "Grandesign-Neue-Serif-bold-italic" },
    { value: "125", text: "Gran Design Neue Serif Italic", font: "Grandesign-Neue-Serif-italic" },
    { value: "126", text: "Literata 18pt", font: "Literata-18pt" },
    { value: "127", text: "Literata 18pt Italic", font: "Literata-18pt-italic" },
    { value: "128", text: "Literata 18pt Bold", font: "Literata-18pt-bold" },
    { value: "129", text: "Literata 18pt Bold Italic", font: "Literata-18pt-bold-italic" },
    { value: "130", text: "Literata 18pt Black", font: "Literata-18pt-black" },
    { value: "131", text: "Literata 18pt Black Italic", font: "Literata-18pt-black-italic" },
    { value: "132", text: "Literata 18pt Extra Bold", font: "Literata-18pt-extra-bold" },
    { value: "133", text: "Literata 18pt Extra Bold Italic", font: "Literata-18pt-extra-bold-italic" },
    { value: "134", text: "Literata 18pt Light", font: "Literata-18pt-light" },
    { value: "135", text: "Literata 18pt Light italic", font: "Literata-18pt-light-italic" },
    { value: "136", text: "Literata 18pt Extra Light", font: "Literata-18pt-extra-light" },
    { value: "137", text: "Literata 18pt Extra Light Italic", font: "Literata-18pt-extra-light-italic" },
    { value: "138", text: "Literata 18pt Medium", font: "Literata-18pt-medium" },
    { value: "139", text: "Literata 18pt Semi Bold", font: "Literata-18pt-semi-bold" },
    { value: "140", text: "Literata 18pt Semi Bold Italic", font: "Literata-18pt-semi-bold-italic" },
    { value: "141", text: "Literata 24pt", font: "Literata-24pt" },
    { value: "142", text: "Literata 24pt Italic", font: "Literata-24pt-italic" },
    { value: "143", text: "Literata 24pt Bold", font: "Literata-24pt-bold" },
    { value: "144", text: "Literata 24pt Bold Italic", font: "Literata-24pt-bold-italic" },
    { value: "145", text: "Literata 24pt Black", font: "Literata-24pt-black" },
    { value: "146", text: "Literata 24pt Black Italic", font: "Literata-24pt-black-italic" },
    { value: "147", text: "Literata 24pt Extra Bold", font: "Literata-24pt-extra-bold" },
    { value: "148", text: "Literata 24pt Extra Bold Italic", font: "Literata-24pt-extra-bold-italic" },
    { value: "149", text: "Literata 24pt Light", font: "Literata-24pt-light" },
    { value: "150", text: "Literata 24pt Light italic", font: "Literata-24pt-light-italic" },
    { value: "151", text: "Literata 24pt Extra Light", font: "Literata-24pt-extra-light" },
    { value: "152", text: "Literata 24pt Extra Light Italic", font: "Literata-24pt-extra-light-italic" },
    { value: "153", text: "Literata 24pt Medium", font: "Literata-24pt-medium" },
    { value: "154", text: "Lora", font: "Lora" },
    { value: "155", text: "Lora Italic", font: "Lora-italic" },
    { value: "156", text: "Lora Bold", font: "Lora-bold" },
    { value: "157", text: "Lora Bold Italic", font: "Lora-bold-italic" },
    { value: "158", text: "Lora Semi Bold", font: "Lora-semi-bold" },
    { value: "159", text: "Lora Semi Bold Italic", font: "Lora-semi-bold-italic" },
    { value: "160", text: "Lora Medium", font: "Lora-medium" },
    { value: "161", text: "Lora Medium Italic", font: "Lora-medium-italic" },
    { value: "162", text: "Mermaid", font: "Mermaid" },
    { value: "163", text: "Mermaid Swash Caps", font: "Mermaid-Swash-Caps" },
    { value: "164", text: "Playfair Display", font: "Playfair-Display" },
    { value: "165", text: "Playfair Display Italic", font: "Playfair-Display-italic" },
    { value: "166", text: "Playfair Display Bold", font: "Playfair-Display-bold" },
    { value: "167", text: "Playfair Display Bold Italic", font: "Playfair-Display-bold-italic" },
    { value: "168", text: "Playfair Display Black", font: "Playfair-Display-black" },
    { value: "169", text: "Playfair Display Black Italic", font: "Playfair-Display-black-italic" },
    { value: "170", text: "Playfair Display Extra Bold", font: "Playfair-Display-extra-bold" },
    { value: "171", text: "Playfair Display Extra Bold Italic", font: "Playfair-Display-extra-bold-italic" },
    { value: "172", text: "Playfair Display Semi Bold", font: "Playfair-Display-semi-bold" },
    { value: "173", text: "Playfair Display Semi Bold Italic", font: "Playfair-Display-semi-bold-italic" },
    { value: "174", text: "Playfair Display Medium", font: "Playfair-Display-medium" },
    { value: "175", text: "Playfair Display Medium Italic", font: "Playfair-Display-medium-italic" },
    { value: "176", text: "Playfair Display SC", font: "Playfair-Display-SC" },
    { value: "177", text: "Playfair Display SC Italic", font: "Playfair-Display-SC-italic" },
    { value: "178", text: "Playfair Display SC Bold", font: "Playfair-Display-SC-bold" },
    { value: "179", text: "Playfair Display SC Bold Italic", font: "Playfair-Display-SC-bold-italic" },
    { value: "180", text: "Playfair Display SC Black", font: "Playfair-Display-SC-black" },
    { value: "181", text: "Playfair Display SC Black Italic", font: "Playfair-Display-SC-black-italic" },
    { value: "182", text: "Ripe", font: "Ripe" },
    { value: "183", text: "Ripe Bold", font: "Ripe-bold" },
    { value: "184", text: "Ripe Light", font: "Ripe-light" },
    { value: "185", text: "Ripe Semi Bold", font: "Ripe-semi-bold" },
    { value: "186", text: "Roger White", font: "RogerWhite" },
    { value: "187", text: "Sailer Trial", font: "Sailer-Trial" },
    { value: "188", text: "Sailer Trial Bold", font: "Sailer-Trial-bold" },
    { value: "189", text: "Sailer Trial Semi Bold", font: "Sailer-Trial-SemBd" },
    { value: "190", text: "Sailer Trial Extra Bold", font: "Sailer-Trial-ExtBd" },
    { value: "191", text: "Sailer Trial Light", font: "Sailer-Trial-light" },
    { value: "192", text: "Sailer Trial Medium", font: "Sailer-Trial-Medium" },
    { value: "193", text: "Sailer Trial Thin", font: "Sailer-Trial-thin" },
    { value: "194", text: "Souvenir 2", font: "Souvenir2" },
    { value: "195", text: "Souvenir", font: "Souvenir" },
    { value: "196", text: "Souvenir Italic", font: "Souvenir-italic" },
    { value: "197", text: "Souvenir Light", font: "Souvenir-light" },
    { value: "198", text: "Souvenir Bold", font: "Souvenir-bold" },
    { value: "199", text: "Souvenir Bold Italic", font: "Souvenir-bold-italic" },
    { value: "200", text: "Souvenir Demi Italic", font: "Souvenir-DemiItalic" },
    { value: "201", text: "Souvenir Light Bold", font: "Souvenir-Light-Bold-Wd" },
    { value: "202", text: "Souvenir Light Bold Italic", font: "Souvenir-Light-bold-italic" },
    { value: "203", text: "Souvenir SSi", font: "Souvenir-SSi" },
    { value: "204", text: "Souvenir SSi Bold", font: "Souvenir-SSi-bold" },
    { value: "205", text: "Souvenir SSi Light", font: "Souvenir-Light-SSi-light" },
    { value: "206", text: "Souvenir SSi Black Bold", font: "Souvenir-Black-SSi-bold" },
    { value: "207", text: "Souvenir SSi Black Bold Italic", font: "Souvenir-Black-SSi-bold-italic" },
    { value: "208", text: "ITC Souvenir Std", font: "ITC-Souvenir-Std" },
    { value: "209", text: "ITC Souvenir Std Bold", font: "ITC-Souvenir-Std-bold" },
    { value: "210", text: "ITC Souvenir Std Light", font: "ITC-Souvenir-Std-light" },
    { value: "211", text: "ITC Souvenir Std Medium", font: "ITC-Souvenir-Std-medium" },
    { value: "212", text: "Sreda", font: "Sreda" },
    { value: "213", text: "Sun Flower", font: "Sunflower" },
    { value: "214", text: "Unna", font: "Unna" },
    { value: "215", text: "Unna Italic", font: "Unna-italic" },
    { value: "216", text: "Unna Bold", font: "Unna-bold" },
    { value: "217", text: "Unna Bold Italic", font: "Unna-bold-italic" },
    { value: "218", text: "Adam", font: "ADAM" },
    { value: "219", text: "Aleo", font: "Aleo-regular" },
    { value: "220", text: "Aleo Bold", font: "Aleo-bold" },
    { value: "221", text: "Aleo Light", font: "Aleo-light" },
    { value: "222", text: "Alfa", font: "Alfa" },
    { value: "223", text: "Anonymous Pro", font: "Anonymous-Pro" },
    { value: "224", text: "Anonymous Pro Bold", font: "Anonymous-bold" },
    { value: "225", text: "Anson", font: "Anson" },
    { value: "226", text: "Archive 2", font: "Archive2" },
    { value: "227", text: "Archivo Black", font: "Archivo-black" },
    { value: "228", text: "Azedo Light", font: "Azedo-light" },
    { value: "229", text: "Azedo Bold", font: "Azedo-bold" },
    { value: "230", text: "Bariol", font: "Bariol" },
    { value: "231", text: "Baskervville", font: "Baskervville" },
    { value: "232", text: "Baskervville Italic", font: "Baskervville-italic" },
    { value: "233", text: "Beaver", font: "Beaver" },
    { value: "234", text: "Big John", font: "Big-John" },
    { value: "235", text: "Slim Joe", font: "Slim-Joe" },
    { value: "236", text: "Biko", font: "Biko" },
    { value: "237", text: "Biko Bold", font: "Biko-bold" },
    { value: "238", text: "Biko Black", font: "Biko-black" },
    { value: "239", text: "Biko Light", font: "Biko-light" },
    { value: "240", text: "Blanch Caps", font: "Blanch-Caps" },
    { value: "241", text: "Blanch Caps Light", font: "Blanch-Caps-Light" },
    { value: "242", text: "Blanch Caps Inline", font: "Blanch-Caps-Inline" },
    { value: "243", text: "Blanch Condensed", font: "Blanch-Condensed" },
    { value: "244", text: "Blanch Condensed Light", font: "Blanch-Condensed-Light" },
    { value: "245", text: "Blanch Condensed Inline", font: "Blanch-Condensed-Inline" },
    { value: "246", text: "Boris Black Bloxx", font: "BorisBlackBloxx" },
    { value: "247", text: "Boris Black Bloxx Dirty", font: "BorisBlackBloxxDirty" },
    { value: "248", text: "BPreplay", font: "BPreplay" },
    { value: "249", text: "BPreplay Italic", font: "BPreplay-italic" },
    { value: "250", text: "BPreplay Bold", font: "BPreplay-bold" },
    { value: "251", text: "BPreplay Bold Italic", font: "BPreplay-bold-italic" },
    { value: "252", text: "Bungee", font: "Bungee" },
    { value: "253", text: "Cabin", font: "Cabin" },
    { value: "254", text: "Cabin Italic", font: "Cabin-italic" },
    { value: "255", text: "Cabin Bold", font: "Cabin-bold" },
    { value: "256", text: "Cabin Bold Italic", font: "Cabin-bold-italic" },
    { value: "257", text: "Cabin Medium", font: "Cabin-medium" },
    { value: "258", text: "Cabin Medium Italic", font: "Cabin-medium-italic" },
    { value: "259", text: "Cabin Semi Bold", font: "Cabin-semi-bold" },
    { value: "260", text: "Cabin Semi Bold Italic", font: "Cabin-semi-bold-italic" },
    { value: "261", text: "CartoGothic Book", font: "CartoGothic-book" },
    { value: "262", text: "CartoGothic Italic", font: "CartoGothic-italic" },
    { value: "263", text: "CartoGothic Bold", font: "CartoGothic-bold" },
    { value: "264", text: "CartoGothic Bold Italic", font: "CartoGothic-bold-italic" },
    { value: "265", text: "Champagne & Limousines", font: "Champagne-Limousines" },
    { value: "266", text: "Champagne & Limousines Italic", font: "Champagne-Limousines-italic" },
    { value: "267", text: "Champagne & Limousines Bold", font: "Champagne-Limousines-bold" },
    { value: "268", text: "Champagne & Limousines Bold Italic", font: "Champagne-Limousines-bold-italic" },
    { value: "269", text: "Coco Gothic", font: "Coco-Gothic" },
    { value: "270", text: "Coco Gothic Italic", font: "Coco-Gothic-italic" },
    { value: "271", text: "Coco Gothic Bold", font: "Coco-Gothic-bold" },
    { value: "272", text: "Coco Gothic Bold Italic", font: "Coco-Gothic-bold-italic" },
    { value: "273", text: "Coco Gothic Extra Light", font: "Coco-Gothic-Extra-Light" },
    { value: "274", text: "Coco Gothic Extra Light Italic", font: "Coco-Gothic-Extra-Light-italic" },
    { value: "275", text: "Coco Gothic Light", font: "Coco-Gothic-light" },
    { value: "276", text: "Coco Gothic Light Italic", font: "Coco-Gothic-light-italic" },
    { value: "277", text: "Coco Gothic Extra Black", font: "Coco-Gothic-Extra-black" },
    { value: "278", text: "Coco Gothic Extra Black Italic", font: "Coco-Gothic-extra-black-italic" },
    { value: "279", text: "Coco Gothic Heavy", font: "Coco-Gothic-heavy" },
    { value: "280", text: "Coco Gothic Heavy Italic", font: "Coco-Gothic-heavy-italic" },
    { value: "281", text: "Code light", font: "Code-light" },
    { value: "282", text: "Code Bold", font: "Code-bold" },
    { value: "283", text: "Comfortaa", font: "Comfortaa" },
    { value: "284", text: "Comfortaa Light", font: "Comfortaa-light" },
    { value: "285", text: "Comfortaa Bold", font: "Comfortaa-bold" },
    { value: "286", text: "Comic Neue 1 1", font: "Comic-Neue-1-1" },
    { value: "287", text: "Comic Neue 1 1 Bold", font: "Comic-Neue-1-1-bold" },
    { value: "288", text: "Comic Neue 1 1 Bold Oblique", font: "Comic-Neue-1-1-bold-oblique" },
    { value: "289", text: "Comic Neue 1 1 Oblique", font: "Comic-Neue-1-1-oblique" },
    { value: "300", text: "Comic Neue 1 1 Light", font: "Comic-Neue-1-1-light" },
    { value: "301", text: "Comic Neue 1 1 Light Oblique", font: "Comic-Neue-1-1-light-oblique" },
    { value: "302", text: "Comic Neue 1 1 Angular", font: "Comic-Neue-1-1-Angular" },
    { value: "303", text: "Comic Neue 1 1 Angular Bold", font: "Comic-Neue-1-1-Angular-bold" },
    { value: "304", text: "Comic Neue 1 1 Angular Light", font: "Comic-Neue-1-1-Angular-light" },
    { value: "305", text: "Comic Neue 1 1 Angular Oblique", font: "Comic-Neue-1-1-Angular-oblique" },
    { value: "306", text: "Comic Neue 1 1 Angular Oblique Bold", font: "Comic-Neue-1-1-Angular-oblique-bold" },
    { value: "307", text: "Comic Neue 1 1 Angular Oblique Light", font: "Comic-Neue-1-1-Angular-oblique-light" },
    { value: "308", text: "Comic-Neue", font: "Comic-Neue" },
    { value: "309", text: "Comic-Neue Italic", font: "Comic-Neue-italic" },
    { value: "310", text: "Comic-Neue Bold", font: "Comic-Neue-bold" },
    { value: "311", text: "Comic-Neue Bold Italic", font: "Comic-Neue-bold-italic" },
    { value: "312", text: "Comic-Neue Light", font: "Comic-Neue-light" },
    { value: "313", text: "Comic-Neue Light Italic", font: "Comic-Neue-light-italic" },
    { value: "314", text: "Coolvetica", font: "Coolvetica-Rg" },
    { value: "315", text: "Coolvetica Italic", font: "Coolvetica-Rg-italic" },
    { value: "316", text: "Coolvetica Condensed", font: "Coolvetica-Condensed-Rg" },
    { value: "317", text: "Coolvetica Condensed Heavy", font: "Coolvetica-Compressed-Hv" },
    { value: "318", text: "Coolvetica Creamed", font: "Coolvetica-Crammed-Rg" },
    { value: "319", text: "Creato Display", font: "Creato-Display" },
    { value: "320", text: "Creato Display Italic", font: "Creato-Display-italic" },
    { value: "321", text: "Creato Display Bold", font: "Creato-Display-bold" },
    { value: "322", text: "Creato Display Bold Italic", font: "Creato-Display-bold-italic" },
    { value: "323", text: "Creato Display Extra Bold", font: "Creato-Display-extra-bold" },
    { value: "324", text: "Creato Display Extra Bold Italic", font: "Creato-Display-extra-bold-italic" },
    { value: "325", text: "Creato Display Black", font: "Creato-Display-black" },
    { value: "326", text: "Creato Display Black Italic", font: "Creato-Display-black-italic" },
    { value: "327", text: "Creato Display Medium", font: "Creato-Display-medium" },
    { value: "328", text: "Creato Display Medium Italic", font: "Creato-Display-medium-italic" },
    { value: "329", text: "Creato Display Light", font: "Creato-Display-light" },
    { value: "330", text: "Creato Display Light Italic", font: "Creato-Display-light-italic" },
    { value: "331", text: "Creato Display Thin", font: "Creato-Display-thin" },
    { value: "332", text: "Creato Display Thin Italic", font: "Creato-Display-thin-italic" },
    { value: "333", text: "Diavlo Book", font: "Diavlo-Book" },
    { value: "334", text: "Diavlo Bold", font: "Diavlo-bold" },
    { value: "335", text: "Diavlo Light", font: "Diavlo-light" },
    { value: "336", text: "Diavlo Medium", font: "Diavlo-medium" },
    { value: "337", text: "DM Mono", font: "DM-Mono" },
    { value: "338", text: "DM Mono italic", font: "DM-Mono-italic" },
    { value: "339", text: "DM Mono Light", font: "DM-Mono-light" },
    { value: "340", text: "DM Mono Light Italic", font: "DM-Mono-light-italic" },
    { value: "341", text: "DM Mono Medium", font: "DM-Mono-medium" },
    { value: "342", text: "DM Mono Medium Italic", font: "DM-Mono-medium-italic" },
    { value: "343", text: "Fredoka One", font: "Fredoka-One" },
    { value: "344", text: "Frutiger Bold", font: "Frutiger-bold" },
    { value: "345", text: "Futura Round Bold", font: "a_FuturaRound-bold" },
    { value: "346", text: "Futura OrtoSh Bold", font: "a_FuturaOrtoSh-bold" },
    { value: "347", text: "Futura Condensed Extra Bold Normal", font: "Futura-CondensedExtraBold-Normal" },
    { value: "348", text: "Futura Book Bold", font: "Futura_Book-bold" },
    { value: "349", text: "Futura Extra Bold", font: "Futura-extra-bold" },
    { value: "350", text: "Futura Orto Bold", font: "Futura-extra-bold" },
    { value: "351", text: "Futura ICGX Bold", font: "Futura-ICG-XBold" },
    { value: "352", text: "Futura LH Extra Bold", font: "Futura-LH-Extra-bold" },
    { value: "353", text: "Futura URW Bold", font: "Futura-URW-bold" },
    { value: "354", text: "Geologica", font: "Geologica" },
    { value: "355", text: "Geologica Bold", font: "Geologica-bold" },
    { value: "356", text: "Geologica Extra Bold", font: "Geologica-extra-bold" },
    { value: "357", text: "Geologica Semi Bold", font: "Geologica-semi-bold" },
    { value: "358", text: "Geologica Light", font: "Geologica-light" },
    { value: "359", text: "Geologica Extra Light", font: "Geologica-extra-light" },
    { value: "360", text: "Geologica Thin", font: "Geologica-thin" },
    { value: "361", text: "Geologica Black", font: "Geologica-black" },
    { value: "362", text: "Geologica Medium", font: "Geologica-medium" },
    { value: "363", text: "Geologica Cursive", font: "Geologica-Cursive" },
    { value: "364", text: "Geologica Cursive Bold", font: "Geologica-Cursive-bold" },
    { value: "365", text: "Geologica Cursive Semi Bold", font: "Geologica-Cursive-semi-bold" },
    { value: "366", text: "Geologica Cursive Extra Bold", font: "Geologica-Cursive-extra-bold" },
    { value: "367", text: "Geologica Cursive Light", font: "Geologica-Cursive-light" },
    { value: "368", text: "Geologica Cursive Extra Light", font: "Geologica-Cursive-extra-light" },
    { value: "369", text: "Geologica Cursive Thin", font: "Geologica-Cursive-thin" },
    { value: "370", text: "Geologica Cursive Medium", font: "Geologica-Cursive-medium" },
    { value: "371", text: "Geologica Cursive Black", font: "Geologica-Cursive-black" },
    { value: "372", text: "Geologica Roman", font: "Geologica-Roman" },
    { value: "373", text: "Geologica Roman Bold", font: "Geologica-Roman-bold" },
    { value: "374", text: "Geologica Roman Extra Bold", font: "Geologica-Roman-extra-bold" },
    { value: "375", text: "Geologica Roman semi Bold", font: "Geologica-Roman-semi-bold" },
    { value: "376", text: "Geologica Roman Light", font: "Geologica-Roman-light" },
    { value: "377", text: "Geologica Roman Extra Light", font: "Geologica-Roman-extra-light" },
    { value: "378", text: "Geologica Roman Thin", font: "Geologica-Roman-thin" },
    { value: "379", text: "Geologica Roman Black", font: "Geologica-Roman-black" },
    { value: "380", text: "Geosans Light", font: "GeosansLight" },
    { value: "381", text: "Geosans Light Oblique", font: "GeosansLight-oblique" },
    { value: "382", text: "Glober Thin", font: "Glober-Thin" },
    { value: "383", text: "Glober SemiBold", font: "Glober-SemiBold" },
    { value: "384", text: "Gontserrat", font: "Gontserrat" },
    { value: "385", text: "Gontserrat Italic", font: "Gontserrat-italic" },
    { value: "386", text: "Gontserrat Bold", font: "Gontserrat-bold" },
    { value: "387", text: "Gontserrat Bold Italic", font: "Gontserrat-bold-italic" },
    { value: "388", text: "Gontserrat Black", font: "Gontserrat-black" },
    { value: "389", text: "Gontserrat Black Italic", font: "Gontserrat-black-italic" },
    { value: "390", text: "Gontserrat Extra Bold", font: "Gontserrat-extra-bold" },
    { value: "391", text: "Gontserrat Extra Bold Italic", font: "Gontserrat-extra-bold-italic" },
    { value: "392", text: "Gontserrat Light", font: "Gontserrat-light" },
    { value: "393", text: "Gontserrat Light Italic", font: "Gontserrat-light-italic" },
    { value: "394", text: "Gontserrat Extra Light", font: "Gontserrat-extra-light" },
    { value: "395", text: "Gontserrat Extra Light Italic", font: "Gontserrat-extra-light-italic" },
    { value: "396", text: "Gontserrat Medium", font: "Gontserrat-medium" },
    { value: "397", text: "Gontserrat Medium Italic", font: "Gontserrat-medium-italic" },
    { value: "398", text: "Gontserrat Semi Bold", font: "Gontserrat-semi-bold" },
    { value: "399", text: "Gontserrat Semi Bold", font: "Gontserrat-semi-bold-italic" },
    { value: "400", text: "Gontserrat Thin", font: "Gontserrat-thin" },
    { value: "401", text: "Gontserrat Thin Italic", font: "Gontserrat-thin-italic" },
    { value: "402", text: "Harabara", font: "Harabara" },
    { value: "403", text: "Helvetica Inserat Cyrillic", font: "Helvetica-Inserat-Cyrillic" },
    { value: "404", text: "Helvetica Inserat BQ", font: "Helvetica-Inserat-BQ" },
    { value: "405", text: "Helvetica Inserat BQ Italic", font: "Helvetica-Inserat-BQ-italic" },
    { value: "406", text: "Helvetica Inserat Roman", font: "Helvetica-Inserat-roman" },
    { value: "407", text: "Helvetica Inserat Roman Semi Bold", font: "Helvetica-Inserat-Roman-SemiB" },
    { value: "408", text: "Helvetica Inserat LT Std Roman", font: "Helvetica-Inserat-LT-Std-roman" },
    { value: "409", text: "Helvetica Neue", font: "Helvetica-Neue" },
    { value: "410", text: "Helvetica Neue italic", font: "Helvetica-Neue-italic" },
    { value: "411", text: "Helvetica Neue Bold", font: "Helvetica-Neue-bold" },
    { value: "412", text: "Helvetica Neue Bold Italic", font: "Helvetica-Neue-bold-italic" },
    { value: "413", text: "Helvetica Neue Condensed Bold", font: "Helvetica-Neue-condensed-bold" },
    { value: "414", text: "Helvetica Neue Condensed Black", font: "Helvetica-Neue-condensed-black" },
    { value: "415", text: "Helvetica Neue Light", font: "Helvetica-Neue-light" },
    { value: "416", text: "Helvetica Neue Light Italic", font: "Helvetica-Neue-light-italic" },
    { value: "417", text: "Helvetica Neue Ultra Light", font: "Helvetica-Neue-ultra-light" },
    { value: "418", text: "Helvetica Neue Ultra Light Italic", font: "Helvetica-Neue-ultra-light-italic" },
    { value: "419", text: "Helvetica Neue Medium", font: "Helvetica-Neue-medium" },
    { value: "420", text: "Herald", font: "Herald" },
    { value: "421", text: "HP Simplified", font: "HP-Simplified" },
    { value: "422", text: "HP Simplified Italic", font: "HP-Simplified-italic" },
    { value: "423", text: "HP Simplified Bold", font: "HP-Simplified-bold" },
    { value: "424", text: "HP Simplified Bold Italic", font: "HP-Simplified-bold-italic" },
    { value: "425", text: "HP Simplified Light", font: "HP-Simplified-light" },
    { value: "426", text: "HP Simplified Light Italic", font: "HP-Simplified-light-italic" },
    { value: "427", text: "Inter 18pt", font: "Inter-18pt" },
    { value: "428", text: "Inter 18pt Italic", font: "Inter-18pt-italic" },
    { value: "429", text: "Inter 18pt Bold", font: "Inter-18pt-bold" },
    { value: "430", text: "Inter 18pt Bold Italic", font: "Inter-18pt-bold-italic" },
    { value: "431", text: "Inter 18pt Black", font: "Inter-18pt-black" },
    { value: "432", text: "Inter 18pt Black Italic", font: "Inter-18pt-black-italic" },
    { value: "433", text: "Inter 18pt Extra bold", font: "Inter-18pt-extra-bold" },
    { value: "434", text: "Inter 18pt Extra bold Italic", font: "Inter-18pt-extra-bold-italic" },
    { value: "435", text: "Inter 18pt Light", font: "Inter-18pt-light" },
    { value: "436", text: "Inter 18pt Light Italic", font: "Inter-18pt-light-italic" },
    { value: "437", text: "Inter 18pt Extra Light", font: "Inter-18pt-extra-light" },
    { value: "438", text: "Inter 18pt Extra Light Italic", font: "Inter-18pt-extra-light-italic" },
    { value: "439", text: "Inter 18pt Medium", font: "Inter-18pt-medium" },
    { value: "440", text: "Inter 18pt Medium Italic", font: "Inter-18pt-medium-italic" },
    { value: "441", text: "Inter 18pt Semi Bold", font: "Inter-18pt-semi-bold" },
    { value: "442", text: "Inter 18pt Semi Bold Italic", font: "Inter-18pt-semi-bold-italic" },
    { value: "443", text: "Inter 18pt Thin", font: "Inter-18pt-thin" },
    { value: "444", text: "Inter 18pt Thin Italic", font: "Inter-18pt-thin-italic" },
    { value: "445", text: "Inter 24pt Italic", font: "Inter-24pt-italic" },
    { value: "446", text: "Inter 24pt Bold", font: "Inter-24pt-bold" },
    { value: "447", text: "Inter 24pt Bold Italic", font: "Inter-24pt-bold-italic" },
    { value: "448", text: "Inter 24pt Black", font: "Inter-24pt-black" },
    { value: "449", text: "Inter 24pt Black Italic", font: "Inter-24pt-black-italic" },
    { value: "450", text: "Inter 24pt Extra Bold", font: "Inter-24pt-extra-bold" },
    { value: "451", text: "Inter 24pt Extra Bold Italic", font: "Inter-24pt-extra-bold-italic" },
    { value: "452", text: "Inter 24pt Light", font: "Inter-24pt-light" },
    { value: "453", text: "Inter 24pt Light Italic", font: "Inter-24pt-light-italic" },
    { value: "454", text: "Inter 24pt Extra Light", font: "Inter-24pt-extra-light" },
    { value: "455", text: "Inter 24pt Extra Light Italic", font: "Inter-24pt-extra-light-italic" },
    { value: "456", text: "Inter 24pt Medium", font: "Inter-24pt-medium" },
    { value: "457", text: "Jaapokki", font: "Jaapokki" },
    { value: "458", text: "Jaapokki Enchance", font: "Jaapokki-enchance" },
    { value: "459", text: "Jaapokki Subtract", font: "Jaapokki-subtract" },
    { value: "460", text: "Kankin", font: "Kankin" },
    { value: "461", text: "Kenyan Coffee", font: "Kenyan-Coffee-Rg" },
    { value: "462", text: "Kenyan Coffee Italic", font: "Kenyan-Coffee-Rg-italic" },
    { value: "463", text: "Kenyan Coffee Bold", font: "Kenyan-Coffee-Rg-bold" },
    { value: "464", text: "Kenyan Coffee Bold Italic", font: "Kenyan-Coffee-Rg-bold-italic" },
    { value: "465", text: "Kirvy", font: "Kirvy" },
    { value: "466", text: "Kirvy Bold", font: "Kirvy-bold" },
    { value: "467", text: "Kirvy Light", font: "Kirvy-light" },
    { value: "468", text: "Kirvy Thint", font: "Kirvy-thin" },
    { value: "469", text: "Lato", font: "Lato" },
    { value: "470", text: "Lato Italic", font: "Lato-italic" },
    { value: "471", text: "Lato Bold", font: "Lato-bold" },
    { value: "472", text: "Lato Bold Italic", font: "Lato-bold-italic" },
    { value: "473", text: "Lato Black", font: "Lato-black" },
    { value: "474", text: "Lato Black Italic", font: "Lato-black-italic" },
    { value: "475", text: "Lato Light", font: "Lato-light" },
    { value: "476", text: "Lato Light Italic", font: "Lato-light-italic" },
    { value: "477", text: "Lato Hairline", font: "Lato-Hairline" },
    { value: "478", text: "Lato Hairline Italic", font: "Lato-Hairline-italic" },
    { value: "480", text: "Lato 20", font: "Lato-20" },
    { value: "481", text: "Lato 20 Italic", font: "Lato-20-italic" },
    { value: "482", text: "Lato 20 Bold", font: "Lato-20-bold" },
    { value: "483", text: "Lato 20 Bold Italic", font: "Lato-20-bold-italic" },
    { value: "484", text: "Lato 20 Black", font: "Lato-20-black" },
    { value: "485", text: "Lato 20 Black Italic", font: "Lato-20-black-italic" },
    { value: "486", text: "Lato 20 Hairline", font: "Lato-20-Hairline" },
    { value: "487", text: "Lato 20 Hairline Italic", font: "Lato-20-Hairline-italic" },
    { value: "488", text: "Lato 20 Heavy", font: "Lato-20-heavy" },
    { value: "489", text: "Lato 20 Heavy Italic", font: "Lato-20-heavy-italic" },
    { value: "490", text: "Lato 20 Light", font: "Lato-20-light" },
    { value: "491", text: "Lato 20 Light Italic", font: "Lato-20-light-italic" },
    { value: "492", text: "Lato 20 Medium", font: "Lato-20-medium" },
    { value: "493", text: "Lato 20 Medium Italic", font: "Lato-20-medium-italic" },
    { value: "494", text: "Lato 20 Semi bold", font: "Lato-20-semi-bold" },
    { value: "495", text: "Lato 20 Semi bold Italic", font: "Lato-20-semi-bold-italic" },
    { value: "496", text: "Lato 20 Semi Thin", font: "Lato-20-thin" },
    { value: "497", text: "Lato 20 Semi Thin Italic", font: "Lato-20-thin-italic" },
    { value: "498", text: "Molot", font: "Molot" },
    { value: "499", text: "Mukta Malar", font: "Mukta-Malar" },
    { value: "500", text: "Mukta Malar Bold", font: "Mukta-Malar-bold" },
    { value: "501", text: "Mukta Malar Extra Bold", font: "Mukta-Malar-extra-bold" },
    { value: "502", text: "Mukta Malar Light", font: "Mukta-Malar-light" },
    { value: "503", text: "Mukta Malar Extra Light", font: "Mukta-Malar-extra-light" },
    { value: "504", text: "Mukta Malar Semi Bold", font: "Mukta-Malar-semi-bold" },
    { value: "505", text: "Mukta Malar Medium", font: "Mukta-Malar-medium" },
    { value: "506", text: "Mulish", font: "Mulish" },
    { value: "507", text: "Mulish Italic", font: "Mulish-italic" },
    { value: "508", text: "Mulish Bold", font: "Mulish-bold" },
    { value: "509", text: "Mulish Bold Italic", font: "Mulish-bold-italic" },
    { value: "510", text: "Mulish Black", font: "Mulish-black" },
    { value: "511", text: "Mulish Black Italic", font: "Mulish-black-italic" },
    { value: "512", text: "Mulish Extra Bold", font: "Mulish-extra-bold" },
    { value: "513", text: "Mulish Extra Bold Italic", font: "Mulish-extra-bold-italic" },
    { value: "514", text: "Mulish Semi Bold", font: "Mulish-semi-bold" },
    { value: "515", text: "Mulish Semi Bold Italic", font: "Mulish-semi-bold-italic" },
    { value: "516", text: "Mulish Light", font: "Mulish-light" },
    { value: "517", text: "Mulish Light Italic", font: "Mulish-light-italic" },
    { value: "518", text: "Mulish Extra Light", font: "Mulish-extra-light" },
    { value: "519", text: "Mulish Extra Light Italic", font: "Mulish-extra-light-italic" },
    { value: "520", text: "Mulish Medium", font: "Mulish-medium" },
    { value: "521", text: "Mulish Medium Italic", font: "Mulish-medium-italic" },
    { value: "522", text: "NanumGothic", font: "NanumGothic" },
    { value: "523", text: "NanumGothic Bold", font: "NanumGothic-bold" },
    { value: "524", text: "NanumGothic Extra Bold", font: "NanumGothic-extra-bold" },
    { value: "525", text: "Neris Black", font: "Neris-black" },
    { value: "526", text: "Neris Black Italic", font: "Neris-black-italic" },
    { value: "527", text: "Neris Bold Italic", font: "Neris-bold-italic" },
    { value: "528", text: "Neris Semi Bold", font: "Neris-semi-bold" },
    { value: "529", text: "Neris Semi Bold Italic", font: "Neris-semi-bold-italic" },
    { value: "530", text: "Neris Light", font: "Neris-light" },
    { value: "531", text: "Neris Light Italic", font: "Neris-light-italic" },
    { value: "532", text: "Neris Thin", font: "Neris-thin" },
    { value: "533", text: "Neris Thin Italic", font: "Neris-thin-italic" },
    { value: "534", text: "New Amsterdam", font: "New-Amsterdam" },
    { value: "535", text: "Nexa Light", font: "Nexa-light" },
    { value: "536", text: "Nexa Bold", font: "Nexa-bold" },
    { value: "537", text: "Nolo Contendre", font: "Nolo-Contendre" },
    { value: "538", text: "Nolo Contendre Italic", font: "Nolo-Contendre-italic" },
    { value: "539", text: "Nolo Contendre Condensed", font: "Nolo-Contendre-Condensed" },
    { value: "540", text: "Nolo Contendre condensed Italic", font: "Nolo-Contendre-CondItal" },
    { value: "541", text: "Nolo Contendre Expanded", font: "Nolo-Contendre-Expanded" },
    { value: "542", text: "Nolo Contendre Expanded Italic", font: "Nolo-Contendre-ExpItal" },
    { value: "543", text: "Nolo Contendre Shadow", font: "Nolo-Contendre-Shadow" },
    { value: "544", text: "Nolo Contendre Shadow Italic", font: "Nolo-Contendre-Shadow-italic" },
    { value: "545", text: "nevis Bold", font: "nevis-bold" },
    { value: "546", text: "Norwester", font: "Norwester" },
    { value: "547", text: "Helvetica Neue LT Std 77 Bold Condensed Oblique", font: "Helvetica-Neue-LTStd-bdcno-italic" },
    { value: "548", text: "Helvetica Neue LT Std 73 Bold Extended Oblique", font: "Helvetica-Neue-LTStd-bdexo-italic" },
    { value: "549", text: "Helvetica Neue LT Std 77 Bold Condensed", font: "Helvetica-Neue-LTStd-bold" },
    { value: "550", text: "Helvetica Neue LT Std 75 Bold", font: "Helvetica-Neue-LTStd-bd-bold" },
    { value: "551", text: "Helvetica Neue LT Std 76 Bold Italic", font: "Helvetica-Neue-LTStd-bdit-bold-italic" },
    { value: "552", text: "Helvetica Neue LT Std 75 Bold Outline", font: "Helvetica-Neue-LTStd-nbdou-bold" },
    { value: "553", text: "Helvetica Neue LT Std 73 Bold Extended", font: "Helvetica-Neue-LTStd-bdex-bold" },
    { value: "554", text: "Helvetica Neue LT Std 93 Black Extended Oblique", font: "Helvetica-Neue-LTStd-blkexo-italic" },
    { value: "555", text: "Helvetica Neue LT Std 93 Black Extended", font: "Helvetica-Neue-LTStd-blkex" },
    { value: "556", text: "Helvetica Neue LT Std 97 Black Condensed", font: "Helvetica-Neue-LTStd-blkcn" },
    { value: "557", text: "Helvetica Neue LT Std 95 Black", font: "Helvetica-Neue-LTStd-blk" },
    { value: "558", text: "Helvetica Neue LT Std 97 Black Condensed Oblique", font: "Helvetica-Neue-LTStd-blkcno" },
    { value: "559", text: "Helvetica Neue LT Std 57 Condensed", font: "Helvetica-Neue-LTStd-cn" },
    { value: "560", text: "Helvetica Neue LT Std 53 Extended", font: "Helvetica-Neue-LTStd-ex" },
    { value: "561", text: "Helvetica Neue LT Std 53 Extended Oblique", font: "Helvetica-Neue-LTStd-exo-italic" },
    { value: "562", text: "Helvetica Neue LT Std 57 Condensed Oblique", font: "Helvetica-Neue-LTStd-cno-italic" },
    { value: "563", text: "Helvetica Neue LT Std 96 Black Italic", font: "Helvetica-Neue-LTStd-blkit-italic" },
    { value: "564", text: "Helvetica Neue LT Std 85 Heavy", font: "Helvetica-Neue-LTStd-hv" },
    { value: "565", text: "Helvetica Neue LT Std 45 Light", font: "Helvetica-Neue-LTStd-lt" },
    { value: "566", text: "Helvetica Neue LT Std 47 Light Condensed", font: "Helvetica-Neue-LTStd-ltcn" },
    { value: "567", text: "Helvetica Neue LT Std 83 Heavy Extended Oblique", font: "Helvetica-Neue-LTStd-hvexo" },
    { value: "568", text: "Helvetica Neue LT Std 87 Heavy Condensed Oblique", font: "Helvetica-Neue-LTStd-hvcno" },
    { value: "569", text: "Helvetica Neue LT Std 83 Heavy Extended", font: "Helvetica-Neue-LTStd-hvex" },
    { value: "570", text: "Helvetica Neue LT Std 87 Heavy Condensed", font: "Helvetica-Neue-LTStd-hvcn" },
    { value: "571", text: "Helvetica Neue LT Std 86 Heavy Italic", font: "Helvetica-Neue-LTStd-hvit-italic" },
    { value: "572", text: "Helvetica Neue LT Std 56 Italic", font: "Helvetica-Neue-LTStd-it-italic" },
    { value: "573", text: "Helvetica Neue LT Std 43 Light Extended Oblique", font: "Helvetica-Neue-LTStd-ltexo-italic" },
    { value: "574", text: "Helvetica Neue LT Std 47 Light Condensed Oblique", font: "Helvetica-Neue-LTStd-ltcno-italic" },
    { value: "575", text: "Helvetica Neue LT Std 46 Light Italic", font: "Helvetica-Neue-LTStd-ltit-italic" },
    { value: "576", text: "Helvetica Neue LT Std 43 Light Extended", font: "Helvetica-Neue-LTStd-ltex" },
    { value: "577", text: "Null Free", font: "Null-Free" },
    { value: "578", text: "Nunito Sans 7pt Bold", font: "Nunito-Sans-7pt-bold" },
    { value: "579", text: "Nunito Sans 7pt Bold Italic", font: "Nunito-Sans-7pt-bold-italic" },
    { value: "580", text: "Nunito Sans 7pt Black", font: "Nunito-Sans-7pt-black" },
    { value: "581", text: "Nunito Sans 7pt Black Italic", font: "Nunito-Sans-7pt-black-italic" },
    { value: "582", text: "Nunito Sans 7pt Condensed", font: "Nunito-Sans-7pt-Condensed" },
    { value: "583", text: "Nunito Sans 7pt Condensed Italic", font: "Nunito-Sans-7pt-Condensed-italic" },
    { value: "584", text: "Nunito Sans 7pt Condensed Bold", font: "Nunito-Sans-7pt-Condensed-bold" },
    { value: "585", text: "Nunito Sans 7pt Condensed Bold Italic", font: "Nunito-Sans-7pt-Condensed-bold-italic" },
    { value: "586", text: "Nunito Sans 7pt Condensed Extra Bold", font: "Nunito-Sans-7pt-Condensed-extra-bold" },
    { value: "587", text: "Nunito Sans 7pt Condensed Extra Bold Italic", font: "Nunito-Sans-7pt-Condensed-extra-bold-italic" },
    { value: "588", text: "Nunito Sans 7pt Condensed Light", font: "Nunito-Sans-7pt-Condensed-light" },
    { value: "589", text: "Nunito Sans 7pt Condensed Light Italic", font: "Nunito-Sans-7pt-Condensed-light-italic" },
    { value: "590", text: "Nunito Sans 7pt Condensed Extra Light Italic", font: "Nunito-Sans-7pt-Condensed-extra-light" },
    { value: "591", text: "Nunito Sans 7pt Condensed Extra Light Italic", font: "Nunito-Sans-7pt-Condensed-extra-light-italic" },
    { value: "592", text: "Nunito Sans 7pt Condensed Medium", font: "Nunito-Sans-7pt-Condensed-medium" },
    { value: "593", text: "Nunito Sans 7pt Condensed Medium Italic", font: "Nunito-Sans-7pt-Condensed-medium-italic" },
    { value: "594", text: "Nunito Sans 7pt Condensed Semi Bold", font: "Nunito-Sans-7pt-Condensed-semi-bold" },
    { value: "595", text: "Nunito Sans 7pt Condensed Semi Bold Italic", font: "Nunito-Sans-7pt-Condensed-semi-bold-italic" },
    { value: "597", text: "Nunito Sans 7pt Condensed Expanded Bold", font: "Nunito-Sans-7pt-Expanded-bold" },
    { value: "598", text: "Nunito Sans 7pt Condensed Expanded Bold Italic", font: "Nunito-Sans-7pt-Expanded-bold-italic" },
    { value: "599", text: "Nunito Sans 7pt Condensed Expanded Black", font: "Nunito-Sans-7pt-Expanded-black" },
    { value: "600", text: "Nunito Sans 7pt Condensed Expanded Black Italic", font: "Nunito-Sans-7pt-Expanded-black-italic" },
    { value: "601", text: "Nunito Sans 7pt Condensed Expanded Extra Bold", font: "Nunito-Sans-7pt-Expanded-extra-bold" },
    { value: "602", text: "Nunito Sans 7pt Condensed Expanded Extra Bold Italic", font: "Nunito-Sans-7pt-Expanded-extra-bold-italic" },
    { value: "603", text: "Nunito Sans 7pt Condensed Expanded Light", font: "Nunito-Sans-7pt-Expanded-light" },
    { value: "604", text: "Nunito Sans 7pt Condensed Expanded Extra Light", font: "Nunito-Sans-7pt-Expanded-extra-light" },
    { value: "605", text: "Nunito Sans 7pt Condensed Expanded Extra Light Italic", font: "Nunito-Sans-7pt-Expanded-extra-light-italic" },
    { value: "606", text: "Open Sans", font: "Open-Sans" },
    { value: "607", text: "Open Sans Italic", font: "Open-Sans-italic" },
    { value: "608", text: "Open Sans Bold", font: "Open-Sans-bold" },
    { value: "609", text: "Open Sans Bold Italic", font: "Open-Sans-bold-italic" },
    { value: "610", text: "Open Sans Semi Bold", font: "Open-Sans-semi-bold" },
    { value: "611", text: "Open Sans Semi Bold Italic", font: "Open-Sans-semi-bold-italic" },
    { value: "612", text: "Open Sans Extra Bold", font: "Open-Sans-extra-bold" },
    { value: "613", text: "Open Sans Extra Bold Italic", font: "Open-Sans-extra-bold-italic" },
    { value: "614", text: "Open Sans Light", font: "Open-Sans-light" },
    { value: "615", text: "Open Sans Light Italic", font: "Open-Sans-light-italic" },
    { value: "616", text: "Open Sans Medium", font: "Open-Sans-medium" },
    { value: "617", text: "Open Sans Medium Italic", font: "Open-Sans-medium-italic" },
    { value: "618", text: "Open Sans Condensed", font: "Open-Sans-Condensed" },
    { value: "619", text: "Open Sans Condensed Italic", font: "Open-Sans-Condensed-italic" },
    { value: "620", text: "Open Sans Condensed Bold", font: "Open-Sans-Condensed-bold" },
    { value: "621", text: "Open Sans Condensed Bold Italic", font: "Open-Sans-Condensed-bold-italic" },
    { value: "622", text: "Open Sans Condensed Semi Bold", font: "Open-Sans-Condensed-semi-bold" },
    { value: "623", text: "Open Sans Condensed Semi Bold Italic", font: "Open-Sans-Condensed-semi-bold-italic" },
    { value: "624", text: "Open Sans Condensed Extra Bold", font: "Open-Sans-Condensed-extra-bold" },
    { value: "625", text: "Open Sans Condensed Extra Bold Italic", font: "Open-Sans-Condensed-extra-bold-italic" },
    { value: "626", text: "Open Sans Condensed Light", font: "Open-Sans-Condensed-light" },
    { value: "627", text: "Open Sans Condensed Light Italic", font: "Open-Sans-Condensed-light-italic" },
    { value: "628", text: "Open Sans Condensed Medium", font: "Open-Sans-Condensed-medium" },
    { value: "629", text: "Open Sans Condensed Medium Italic", font: "Open-Sans-Condensed-medium-italic" },
    { value: "630", text: "Open Sans Semi Condensed Italic", font: "Open-Sans-SemiCondensed-italic" },
    { value: "631", text: "Open Sans Semi Condensed Bold", font: "Open-Sans-SemiCondensed-bold" },
    { value: "632", text: "Open Sans Semi Condensed Bold Italic", font: "Open-Sans-SemiCondensed-bold-italic" },
    { value: "633", text: "Open Sans Semi Condensed Extra Bold", font: "Open-Sans-SemiCondensed-extra-bold" },
    { value: "634", text: "Open Sans Semi Condensed Extra Bold Italic", font: "Open-Sans-SemiCondensed-extra-bold-italic" },
    { value: "635", text: "Optien", font: "Optien" },
    { value: "636", text: "OSP DIN", font: "OSP-DIN" },
    { value: "637", text: "Oswald", font: "Oswald" },
    { value: "638", text: "Oswald Bold", font: "Oswald-bold" },
    { value: "639", text: "Oswald Semi Bold", font: "Oswald-semi-bold" },
    { value: "640", text: "Oswald Light", font: "Oswald-light" },
    { value: "641", text: "Oswald Extra Light", font: "Oswald-extra-light" },
    { value: "642", text: "Oswald Medium", font: "Oswald-medium" },
    { value: "643", text: "Oswald Stencil Bold", font: "Oswald-Stencil-bold" },
    { value: "644", text: "Oxanium", font: "Oxanium" },
    { value: "645", text: "Oxanium Bold", font: "Oxanium-bold" },
    { value: "646", text: "Oxanium Semi Bold", font: "Oxanium-semi-bold" },
    { value: "647", text: "Oxanium Extra Bold", font: "Oxanium-extra-bold" },
    { value: "648", text: "Oxanium Light", font: "Oxanium-light" },
    { value: "649", text: "Oxanium Extra Light", font: "Oxanium-extra-light" },
    { value: "650", text: "Oxanium Medium", font: "Oxanium-medium" },
    { value: "651", text: "Oxygen", font: "Oxygen" },
    { value: "652", text: "Oxygen Bold", font: "Oxygen-bold" },
    { value: "653", text: "Oxygen Light", font: "Oxygen-light" },
    { value: "654", text: "Palo Alto", font: "PaloAlto" },
    { value: "655", text: "Palo Alto Italic", font: "PaloAlto-italic" },
    { value: "656", text: "Passion One", font: "Passion-One" },
    { value: "657", text: "Passion One bold", font: "Passion-One-bold" },
    { value: "658", text: "Passion One black", font: "Passion-One-black" },
    { value: "659", text: "Paytone One", font: "Paytone-One" },
    { value: "660", text: "Poetsen One", font: "PoetsenOne" },
    { value: "661", text: "Poiret One", font: "PoiretOne" },
    { value: "662", text: "Poppins", font: "Poppins" },
    { value: "663", text: "Poppins Italic", font: "Poppins-italic" },
    { value: "664", text: "Poppins Bold", font: "Poppins-bold" },
    { value: "665", text: "Poppins bold Italic", font: "Poppins-bold-italic" },
    { value: "666", text: "Poppins Black", font: "Poppins-black" },
    { value: "667", text: "Poppins Black Italic", font: "Poppins-black-italic" },
    { value: "668", text: "Poppins Extra Bold", font: "Poppins-extra-bold" },
    { value: "669", text: "Poppins Extra Bold Italic", font: "Poppins-extra-bold-italic" },
    { value: "670", text: "Poppins Semi Bold", font: "Poppins-semi-bold" },
    { value: "671", text: "Poppins Semi Bold Italic", font: "Poppins-semi-bold-italic" },
    { value: "672", text: "Poppins Light", font: "Poppins-light" },
    { value: "673", text: "Poppins Light Italic", font: "Poppins-light-italic" },
    { value: "674", text: "Poppins Extra Light", font: "Poppins-extra-light" },
    { value: "675", text: "Poppins Extra Light Italic", font: "Poppins-extra-light-italic" },
    { value: "676", text: "Poppins Thin ", font: "Poppins-thin" },
    { value: "677", text: "Poppins Thin Italic", font: "Poppins-thin-italic" },
    { value: "678", text: "Poppins Medium", font: "Poppins-medium" },
    { value: "679", text: "Poppins Mediun Italic", font: "Poppins-medium-italic" },
    { value: "680", text: "Practique", font: "Practique" },
    { value: "681", text: "Prime", font: "Prime" },
    { value: "682", text: "Prime Light", font: "Prime-light" },
    { value: "683", text: "Prosto", font: "Prosto" },
    { value: "684", text: "Quicksand", font: "Quicksand" },
    { value: "685", text: "Quicksand Bold", font: "Quicksand-bold" },
    { value: "686", text: "Quicksand Light", font: "Quicksand-light" },
    { value: "687", text: "Raiders", font: "Raiders" },
    { value: "688", text: "Raleway", font: "Raleway" },
    { value: "689", text: "Raleway Italic", font: "Raleway-italic" },
    { value: "690", text: "Raleway Bold", font: "Raleway-bold" },
    { value: "691", text: "Raleway bold Italic", font: "Raleway-bold-italic" },
    { value: "692", text: "Raleway Black", font: "Raleway-black" },
    { value: "693", text: "Raleway Black Italic", font: "Raleway-black-italic" },
    { value: "694", text: "Raleway Extra Bold", font: "Raleway-extra-bold" },
    { value: "695", text: "Raleway Extra Bold Italic", font: "Raleway-extra-bold-italic" },
    { value: "696", text: "Raleway Semi Bold", font: "Raleway-semi-bold" },
    { value: "697", text: "Raleway Semi Bold Italic", font: "Raleway-semi-bold-italic" },
    { value: "698", text: "Raleway Light", font: "Raleway-light" },
    { value: "699", text: "Raleway Light Italic", font: "Raleway-light-italic" },
    { value: "700", text: "Raleway Extra Light", font: "Raleway-extra-light" },
    { value: "701", text: "Raleway Extra Light Italic", font: "Raleway-extra-light-italic" },
    { value: "702", text: "Raleway Thin ", font: "Raleway-thin" },
    { value: "703", text: "Raleway Thin Italic", font: "Raleway-thin-italic" },
    { value: "704", text: "Raleway Medium", font: "Raleway-medium" },
    { value: "705", text: "Raleway Mediun Italic", font: "Raleway-medium-italic" },
    { value: "706", text: "RBNo2", font: "RBNo2" },
    { value: "707", text: "RBNo2 Light Alternative", font: "RBNo2-Light-Alternative" },
    { value: "708", text: "Righteous", font: "Righteous" },
    { value: "709", text: "Roboto", font: "Roboto" },
    { value: "710", text: "Roboto Italic", font: "Roboto-italic" },
    { value: "711", text: "Roboto Bold", font: "Roboto-bold" },
    { value: "712", text: "Roboto bold Italic", font: "Roboto-bold-italic" },
    { value: "713", text: "Roboto Black", font: "Roboto-black" },
    { value: "714", text: "Roboto Black Italic", font: "Roboto-black-italic" },
    { value: "715", text: "Roboto Light", font: "Roboto-light" },
    { value: "716", text: "Roboto Light Italic", font: "Roboto-light-italic" },
    { value: "717", text: "Roboto Thin ", font: "Roboto-thin" },
    { value: "718", text: "Roboto Thin Italic", font: "Roboto-thin-italic" },
    { value: "719", text: "Roboto Medium", font: "Roboto-medium" },
    { value: "720", text: "Roboto Mediun Italic", font: "Roboto-medium-italic" },
    { value: "721", text: "RobotoCondensed", font: "RobotoCondensed" },
    { value: "722", text: "Roboto Condensed Italic", font: "RobotoCondensed-italic" },
    { value: "723", text: "Roboto Condensed Bold", font: "RobotoCondensed-bold" },
    { value: "724", text: "Roboto Condensed bold Italic", font: "RobotoCondensed-bold-italic" },
    { value: "725", text: "Roboto Condensed Black", font: "RobotoCondensed-black" },
    { value: "726", text: "Roboto Condensed Black Italic", font: "RobotoCondensed-black-italic" },
    { value: "727", text: "Roboto Condensed Extra Bold", font: "RobotoCondensed-extra-bold" },
    { value: "728", text: "Roboto Condensed Extra Bold Italic", font: "RobotoCondensed-extra-bold-italic" },
    { value: "729", text: "Roboto Condensed Semi Bold", font: "RobotoCondensed-semi-bold" },
    { value: "730", text: "Roboto Condensed Semi Bold Italic", font: "RobotoCondensed-semi-bold-italic" },
    { value: "731", text: "Roboto Condensed Light", font: "RobotoCondensed-light" },
    { value: "732", text: "Roboto Condensed Light Italic", font: "RobotoCondensed-light-italic" },
    { value: "733", text: "Roboto Condensed Extra Light", font: "RobotoCondensed-extra-light" },
    { value: "734", text: "Roboto Condensed Extra Light Italic", font: "RobotoCondensed-extra-light-italic" },
    { value: "735", text: "Roboto Condensed Thin ", font: "RobotoCondensed-thin" },
    { value: "736", text: "Roboto Condensed Thin Italic", font: "RobotoCondensed-thin-italic" },
    { value: "737", text: "Roboto Condensed Medium", font: "RobotoCondensed-medium" },
    { value: "738", text: "Roboto Condensed Mediun Italic", font: "RobotoCondensed-medium-italic" },
    { value: "739", text: "Rocko FLF", font: "RockoFLF" },
    { value: "740", text: "Rocko FLF Bold", font: "RockoFLF-bold" },
    { value: "741", text: "Rocko Ultra FLF", font: "RockoUltraFLF" },
    { value: "742", text: "Rocko Ultra FLF Bold", font: "RockoUltraFLF-bold" },
    { value: "743", text: "Roddenberry", font: "Roddenberry" },
    { value: "744", text: "Roddenberry Italic", font: "Roddenberry-italic" },
    { value: "745", text: "Rubik", font: "Rubik" },
    { value: "746", text: "Rubik Italic", font: "Rubik-italic" },
    { value: "747", text: "Rubik Bold", font: "Rubik-bold" },
    { value: "748", text: "Rubik bold Italic", font: "Rubik-bold-italic" },
    { value: "749", text: "Rubik Black", font: "Rubik-black" },
    { value: "750", text: "Rubik Black Italic", font: "Rubik-black-italic" },
    { value: "751", text: "Rubik Extra Bold", font: "Rubik-extra-bold" },
    { value: "752", text: "Rubik Extra Bold Italic", font: "Rubik-extra-bold-italic" },
    { value: "753", text: "Rubik Semi Bold", font: "Rubik-semi-bold" },
    { value: "754", text: "Rubik Semi Bold Italic", font: "Rubik-semi-bold-italic" },
    { value: "755", text: "Rubik Light", font: "Rubik-light" },
    { value: "756", text: "Rubik Light Italic", font: "Rubik-light-italic" },
    { value: "757", text: "Rubik Medium", font: "Rubik-medium" },
    { value: "758", text: "Rubik Mediun Italic", font: "Rubik-medium-italic" },
    { value: "759", text: "Secular One", font: "SecularOne" },
    { value: "760", text: "Signika", font: "Signika" },
    { value: "761", text: "Signika Bold", font: "Signika-bold" },
    { value: "762", text: "Signika Semi Bold", font: "Signika-semi-bold" },
    { value: "763", text: "Signika Light", font: "Signika-light" },
    { value: "764", text: "Staatliches", font: "Staatliches" },
    { value: "765", text: "Straczynski", font: "Straczynski" },
    { value: "766", text: "Straczynski Italic", font: "Straczynski-italic" },
    { value: "767", text: "Straczynski Bold", font: "Straczynski-bold" },
    { value: "768", text: "Straczynski Bold Italic", font: "Straczynski-bold-italic" },
    { value: "769", text: "Swis 721 Bold", font: "Swis721-bold" },
    { value: "770", text: "Swis 721 Black", font: "Swis721-black" },
    { value: "771", text: "Syncopate", font: "Syncopate" },
    { value: "772", text: "Syncopate Bold", font: "Syncopate-bold" },
    { value: "773", text: "Tango Sans", font: "TangoSans" },
    { value: "774", text: "Tango Sans Italic", font: "TangoSans-italic" },
    { value: "775", text: "Tango Sans Bold", font: "TangoSans-bold" },
    { value: "776", text: "Tango Sans Bold Italic", font: "TangoSans-bold-italic" },
    { value: "777", text: "Titillium Web", font: "Titillium" },
    { value: "778", text: "Titillium Web Italic", font: "Titillium-italic" },
    { value: "779", text: "Titillium Web Bold", font: "Titillium-bold" },
    { value: "780", text: "Titillium Web Bold Italic", font: "Titillium-bold-italic" },
    { value: "781", text: "Titillium Web Black", font: "Titillium-black" },
    { value: "782", text: "Titillium Web Semi Bold", font: "Titillium-semi-bold" },
    { value: "783", text: "Titillium Web Semi Bold Italic", font: "Titillium-semi-bold-italic" },
    { value: "784", text: "Titillium Web Light", font: "Titillium-light" },
    { value: "785", text: "Titillium Web Light Italic", font: "Titillium-light-italic" },
    { value: "786", text: "Titillium Web Thin", font: "Titillium-thin" },
    { value: "787", text: "Titillium Web Thin Italic", font: "Titillium-thin-italic" },
    { value: "788", text: "Tobago Poster", font: "Tobago-Poster" },
    { value: "789", text: "Tobago Poster Alternate", font: "Tobago-Poster-Alternate" },
    { value: "790", text: "Tobago Poster Shadow", font: "Tobago-Poster-Shadow" },
    { value: "791", text: "Ubuntu", font: "Ubuntu" },
    { value: "792", text: "Ubuntu italic", font: "Ubuntu-italic" },
    { value: "793", text: "Ubuntu Bold", font: "Ubuntu-bold" },
    { value: "794", text: "Ubuntu Bold Italic", font: "Ubuntu-bold-italic" },
    { value: "795", text: "Ubuntu Light", font: "Ubuntu-light" },
    { value: "796", text: "Ubuntu Light Italic", font: "Ubuntu-light-italic" },
    { value: "797", text: "Ubuntu Medium", font: "Ubuntu-medium" },
    { value: "798", text: "Ubuntu Medium Italic", font: "Ubuntu-medium-italic" },
    { value: "799", text: "Unbounded", font: "Unbounded" },
    { value: "800", text: "Unbounded Bold", font: "Unbounded-bold" },
    { value: "801", text: "Unbounded Semi Bold", font: "Unbounded-semi-bold" },
    { value: "802", text: "Unbounded Extra Bold", font: "Unbounded-extra-bold" },
    { value: "803", text: "Unbounded Light", font: "Unbounded-light" },
    { value: "804", text: "Unbounded Extra Light", font: "Unbounded-extra-light" },
    { value: "805", text: "Unbounded Medium", font: "Unbounded-medium" },
    { value: "806", text: "WorldsAtWar BB", font: "WorldsAtWarBB" },
    { value: "807", text: "WorldsAtWar BB Italic", font: "WorldsAtWarBB-italic" },
    { value: "808", text: "Anton", font: "Anton" },
  ]
  export default fontsFormatList;