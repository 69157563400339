<template>
  <div
    class="post-content__canvas-selector"

  >
    <div 
      class="post-content__resize-tools" 
      :style="styles" 
      ref="resizeOut"
      v-if="selected && !isBlocked"
      :class="{'pref': selectedImage}"
      >
       <div
        class="post-content__resize-tl"
        @pointerdown="resize($event, 'top-left')"
      ></div>
      <div
      v-if="selectedImage.typeItem == 'text' && selectedImage.width > 120"
        class="post-content__resize-cl"
        @pointerdown="resize($event, 'center-left')"
      ></div>
      <div
        class="post-content__resize-tr"
        v-if="selectedImage.typeItem == 'text' && selectedImage.width > 120 || selectedImage.typeItem != 'text'"
        @pointerdown="resize($event, 'top-right')"
      ></div>
      <div
        class="post-content__resize-bl"
        v-if="selectedImage.typeItem == 'text' && selectedImage.width > 120 || selectedImage.typeItem != 'text'"
        @pointerdown="resize($event, 'bottom-left')"
      ></div>
      <div
        v-if="selectedImage.typeItem == 'text'"
        class="post-content__resize-cr"
        @pointerdown="resize($event, 'center-right')"
      ></div>
      <div
        v-if="selectedImage.typeItem == 'text' && selectedImage.width > 120 || selectedImage.typeItem != 'text'"
        class="post-content__resize-br"
        @pointerdown="resize($event, 'bottom-right')"
      ></div>
      <div
        class="post-content__resize-box"
        @pointerdown="movePosition($event)"
      ></div>
        <div 
          id="wrapQuill"
          v-if="selectedImage.typeItem == 'text'"
          :class="{ opacity_0 : quillEditor == null || isTextResize == true }"
          :style="{
            position: 'relative',
            transformOrigin: '0 0',
            transform: `scale(${zoom.transform})`,
            width: `${selectedImage.width}px`,
            // width: `max-content`,
            height: `auto`,
            // top: '3px',
            // left: '3px',
            // overflow: 'hidden',
          }"
          >
          <div 
            :style="{
              width: `${selectedImage.width}px`,
              // height: `${selectedImage.height - 10}px`,
            }" 
             id="editorQuill" @paste="handlePaste">
          </div>
        </div>
      <div class="post-content__rotate" @pointerdown="rotation">
        <v-icon size="16" color="#00a5ff">$loading</v-icon>
      </div>
    </div>

    <div 
      class="post-content__resize-tools" 
      v-if="isHoverStyles"
      :style="selectedHoverStyles"
    > 
      <div
        class="post-content__resize-box"
        @mouseout="onMouseOut($event)"
        @pointerdown="onClick($event); movePosition($event)"
        @click="onClick($event)"
      ></div>
    </div>
  </div>
</template>

<script>
import { quillFunctions } from "@design/styleguide";
export default {
  name: "ContentResize",
  data() {
    return {
      countClick: 0,
      clicked: false,
      position: {
        x: 0,
        y: 0,
      },
      prevMouseMovement: {
        x: 0,
        y: 0,
      },
      selectedCorner: null,
      rotate: 0,
      boxCenter: {
        x: 0,
        y: 0,
      },
      isDraging: false,
      isTextResize: false,
      widthAction: '',
      isTextFonSizeIncrease: false,
    };
  },
  computed: {
    quillGetLastSelection() {
      return this.$store.getters["post/drag/quillGetLastSelection"];
    },
    selectedStylesCut() {
      return this.$store.getters["post/drag/selectedStylesCut"];
    },
    counterClick() {
      return this.$store.getters["post/drag/countClick"];
    },
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    selectedImage() {
      return this.$store.getters["post/image/selectedDetail"];
    },
    zoom() {
      return this.$store.getters["post/zoom/zoom"];
    },
    currentZoom() {
      return this.$store.getters['post/zoom/post'];
    },
    texts() {
      const images = this.$store.getters["post/drag/images"];
      return images.filter(({ typeItem }) => typeItem == "text");
    },
    isBlocked() {
      return this.$store.getters['post/sideControls/isBlocked']
    },
    styles() {
      const {
        transform: {
          translate: { x, y },
          rotate,
        },
        width,
        height,
      } = this.$store.getters["post/drag/selectedStylesResize"];

      const translate = `translate(${x * this.zoom.transform}px,${y * this.zoom.transform}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;
      const transform = [translate, rotateDeg];
      return {
        width: width * this.zoom.transform + "px",
        height: height * this.zoom.transform + "px",
        transform: transform.join(" "),
      };
    },
    selectedStylesResize() {
      return this.$store.getters["post/drag/selectedStylesResize"];
    },
    selectedCrop() {
      return this.$store.getters["post/drag/selectedCrop"];
    },
    images() {
      const images = this.$store.getters['post/drag/images'];
      return images.filter(({ typeItem }) => typeItem != 'text')
    },
    texts() {
      const images = this.$store.getters['post/drag/images'];
      return images.filter(({ typeItem }) => typeItem == 'text')
    },
    isHover() {
      return this.$store.getters["post/drag/hover"];
    },
    selectedHoverStyles() {
      const {
        transform: {
          translate: { x, y },
          rotate,
        },
        width,
        height,
      } = this.$store.getters["post/drag/hoverStyles"];

      const translate = `translate(${x * this.zoom.transform}px,${y * this.zoom.transform}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;
      const transform = [translate, rotateDeg];
      return {
        width: width * this.zoom.transform + "px",
        height: height * this.zoom.transform + "px",
        transform: transform.join(" "),
      }
    },
    isHoverStyles ()  {
      return this.$store.getters["post/drag/hoverStyles"];
    },
    quillEditor() {
      return this.$store.getters['post/drag/quillEditor']
    },
    parrafosQuill() {
      return this.$store.getters['post/drag/parrafosQuill']
    },
    paragraphsListDelta() {
      return this.$store.getters["post/drag/paragraphsList"];
    },
    selectedText() {
      let selectTextValue = this.$store.getters["post/drag/selectedText"];
      if (selectTextValue != null) {
        const {
          styles: { fontWeight, fontSize, fontFamily, color },
          key,
        } = selectTextValue;
        
        return {
          fontSize: parseFloat(fontSize.replace("px", "")),
          fontWeight,
          fontFamily,
          color: color == "#000" ? "#000000FF" : color,
          key,
        };
      }
    },
    allImages() {
      return this.$store.getters["post/drag/images"];
    },
    lastAttributesSelected() {
      return this.$store.getters["post/drag/lastAttributesSelected"];
    },
  },
  mounted(){
  },
  watch:{
    selectedStylesResize(n,o){
      let newWidth = n.width;
      let oldWidth = o.width;
      if (newWidth > oldWidth) this.isTextFonSizeIncrease = true; 
      if (newWidth < oldWidth) this.isTextFonSizeIncrease = false;
    },
    selectedImage(newVal,oldVal){
      if(newVal.typeItem == 'text'){
        if(oldVal !== null) this.$store.dispatch("post/drag/UPDATE_IS_EDITING_TEXT_BY_UUID", { uuidd : oldVal.uuid , value: false })
        else if(oldVal.uuid === newVal.uuidd) this.$store.dispatch("post/drag/UPDATE_IS_EDITING_TEXT_BY_UUID", { uuidd : oldVal.uuid , value: true })
        this.$store.dispatch('post/drag/TEXT_CLICK_COUNT', 0);
      }
    },
  },
  methods: {
    handlePaste(event){
      event.preventDefault(); // Evita que el contenido se pegue directamente con estilos
      let value_ =  this.quillEditor.getContents().ops;
      let quillDOM = this.quillEditor.container.firstChild.childNodes;
      const parentItemIndex = this.allImages.findIndex(({ uuid }) => uuid == this.selected);
      
      let firstElement = null;
      quillDOM.forEach((element,index) => {
        if (index === 0) {
          firstElement = element;
        }
        let elementDataFontSize = element.getAttribute("data-font-size") || '24px';
        let elementDataFontFamily = element.getAttribute("data-font-family");
        let savedFontValue = this.lastAttributesSelected.attributes.size.replace("px", "") || '24px';
        let savedFontFamilyValue = this.lastAttributesSelected.attributes.font || 'Arial';
        let savedFontColorValue = this.lastAttributesSelected.attributes.color || '#000000';

        element.setAttribute("data-font-family", savedFontFamilyValue);
        element.style.fontFamily = savedFontFamilyValue;
        elementDataFontFamily =  elementDataFontFamily.replace(/"/g, '')
        let fontsToEditorStore = this.$store.getters["post/drag/fontsToEditor"];
        let isIncludingFontFamily = fontsToEditorStore.includes(elementDataFontFamily);
        element.childNodes.forEach(childElement => {
          childElement.classList.add(`ql-font-${savedFontFamilyValue}`)
          childElement.style.fontSize = `${savedFontValue}px`;
          childElement.style.color = `${savedFontColorValue}`;
        });
        
      });
      
    },
    resize(event, corner) {
      this.clicked = true;
      this.selectedCorner = corner;
      this.prevMouseMovement.x = event.clientX;
      this.prevMouseMovement.y = event.clientY;
      this.position = {
        x: this.selectedStylesResize.transform.translate.x,
        y: this.selectedStylesResize.transform.translate.y,
      };
      window.addEventListener("pointermove", this.onMouseMove);
      window.addEventListener("pointerup", this.onMouseUp);
    },
    changeFontSizeText(a,b){
      
      let listFonts = this.selectedImage.styles.fontsList;
      this.paragraphsListDelta.map((item,index)=>{
        let elementFontSize = null;
        elementFontSize = parseInt(listFonts[index].font);
        (b) ? elementFontSize += 1 : elementFontSize -= 1;
        listFonts[index].font = `${elementFontSize}px`
        this.$store.dispatch("post/drag/SET_GLOBAL_FONT_SIZE", {fontSize:elementFontSize,range:{index:item.start,length:item.end}});
        this.$store.dispatch("post/textControls/CHANGE_FONT_SIZE_IN_TEXT", {
          fontSize: listFonts,
          uuid: this.selected,
          index: this.selectedText.key,
        });
      });
      // console.log('<------------>',listFonts);
      this.$store.dispatch("post/drag/GLOBAL_CHANGE_HEIGHT");
    },
    onMouseMove(event) {
      if(this.isBlocked) return;

      // this.countClick = 0;
      this.$store.dispatch('post/drag/TEXT_CLICK_COUNT', 0);

      this.$store.dispatch('post/drag/UPDATE_HOVER', true);
      
      this.$store.dispatch("post/canvas/IS_TEXT_SELECTED", false);

      
      if (this.clicked) {
        // if (this.quillEditor != null) this.$store.dispatch('post/drag/DESTROY_QUILL');
        console.log('drag', this.selectedImage.isEditing); 
        this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', null);
        this.$store.dispatch('post/drag/ADD_IS_DRAGGING', true);
        this.$store.dispatch('post/drag/UPDATE_IS_EDITING_TEXT_BY_UUID', { uuidd : this.selectedImage.uuid , value: false });
        this.isTextResize = true;
        // if(!this.quillEditor) this.$store.dispatch('post/drag/DISABLED_ALL_EDITING_TEXT');
        // if(this.selectedImage.typeItem == 'text')this.$store.dispatch('post/drag/DESTROY_QUILL');
        let width = this.selectedStylesResize.width;
        let height = this.selectedStylesResize.height;

        const minHeight = 20;
        const minWidth = 20;
        
        const clientX = (width >= minWidth || height >= minHeight)  ? event.clientX : 0;
        const clientY = (width >= minWidth || height >= minHeight)  ? event.clientY : 0;

        let movedMouseX =
          (this.prevMouseMovement.x - clientX) *
          (1 / this.zoom.transform);
        let movedMouseY =
          (this.prevMouseMovement.y - clientY) *
          (1 / this.zoom.transform);

        this.$store.dispatch("post/image/UPDATE_IMAGE_SELECTED", this.selected);
        let cropped = null;
        switch (this.selectedCorner) {
          case "top-left":
            width = this.selectedStylesResize.width + movedMouseX;
            height = this.selectedStylesResize.height + movedMouseY;
            if((width <= minWidth || height <= minHeight)) return;

            if (this.selectedImage.typeItem == "text") this.changeFontSizeText(this.selectedImage,this.isTextFonSizeIncrease);

            if (movedMouseX !== 0) {
              this.position.y -=
                (movedMouseX * this.selectedStylesResize.height) /
                this.selectedStylesResize.width;
              this.position.x -= movedMouseX;
            } else {
              this.position.x -=
                (movedMouseY * this.selectedStylesResize.width) /
                this.selectedStylesResize.height;
              this.position.y -= movedMouseY;
            }
            break;
          case "top-right":
            width = this.selectedStylesResize.width - movedMouseX;
            height = this.selectedStylesResize.height + movedMouseY;
            if((width <= minWidth || height <= minHeight)) return;

            if (this.selectedImage.typeItem == "text") this.changeFontSizeText(this.selectedImage,this.isTextFonSizeIncrease);

            if (movedMouseX !== 0)
              this.position.y +=
                (movedMouseX * this.selectedStylesResize.height) /
                this.selectedStylesResize.width;
            else this.position.y -= movedMouseY;


            break;
          case "bottom-left":
            // width = this.selectedStylesResize.width + movedMouseX;
            height = this.selectedStylesResize.height - movedMouseY;
            width = this.selectedStylesResize.width + movedMouseX;
            if((width <= minWidth || height <= minHeight)) return;
            
            if (this.selectedImage.typeItem == "text")  this.changeFontSizeText(this.selectedImage,this.isTextFonSizeIncrease);
            
            if (movedMouseX !== 0) this.position.x -= movedMouseX;
            else
              this.position.x +=
                (movedMouseY * this.selectedStylesResize.width) /
                this.selectedStylesResize.height;
            break;
          case "center-left":
            width = this.selectedStylesResize.width + movedMouseX;
            console.log("center-left", width);
            this.$store.dispatch('post/drag/GLOBAL_CHANGE_HEIGHT');
            if (this.selectedImage.typeItem != "text"){height = this.selectedStylesResize.height - movedMouseY;}
            
            if(width <= minWidth) return;

            if (movedMouseX !== 0) this.position.x -= movedMouseX;
            else
              this.position.x +=
                (movedMouseY * this.selectedStylesResize.width) /
                this.selectedStylesResize.height;
            break;
          case "bottom-right":
            width = this.selectedStylesResize.width - movedMouseX;
            height = this.selectedStylesResize.height - movedMouseY;
            if((width <= minWidth || height <= minHeight)) return;
            if (this.selectedImage.typeItem == "text") this.changeFontSizeText(this.selectedImage,this.isTextFonSizeIncrease);
            
            break;
          case "center-right":
            width = this.selectedStylesResize.width - movedMouseX;
            this.$store.dispatch('post/drag/GLOBAL_CHANGE_HEIGHT');
            if(width <= minWidth) return;
            if (this.selectedImage.typeItem != "text") {height = this.selectedStylesResize.height - movedMouseY;}
            break;
          default:
            this.position.x -= movedMouseX;
            this.position.y -= movedMouseY;
        }

        if (this.selectedImage.typeItem != "text") {
            if (movedMouseX !== 0) {
              height =
                (width * this.selectedStylesResize.height) /
                this.selectedStylesResize.width;
            } else {
              width =
                (height * this.selectedStylesResize.width) /
                this.selectedStylesResize.height;
            }
        }

        // SI ES IMAGEN
        if (this.selectedImage.cropped) {
          let widthCropped = this.selectedImage.cropped.width;
          let heightCropped = this.selectedImage.cropped.height;

          switch (this.selectedCorner) {
            case "top-left":
              widthCropped = this.selectedImage.cropped.width + movedMouseX;
              heightCropped = this.selectedImage.cropped.height + movedMouseY;
              break;
            case "top-right":
              widthCropped = this.selectedImage.cropped.width - movedMouseX;
              heightCropped = this.selectedImage.cropped.height + movedMouseY;
              break;
            case "bottom-left":
              widthCropped = this.selectedImage.cropped.width + movedMouseX;
              heightCropped = this.selectedImage.cropped.height - movedMouseY;
              break;
            case "bottom-right":
              widthCropped = this.selectedImage.cropped.width - movedMouseX;
              heightCropped = this.selectedImage.cropped.height - movedMouseY;

              break;
          }
          if (this.selectedImage.typeItem != "text") {
            if (movedMouseX !== 0) {
              heightCropped =
                (widthCropped * this.selectedImage.cropped.height) /
                this.selectedImage.cropped.width;
            } else {
              widthCropped =
                (heightCropped * this.selectedImage.cropped.width) /
                this.selectedImage.cropped.height;
            }
          }
          let xCropped = this.selectedImage.cropped.x;
          let yCropped = this.selectedImage.cropped.y;

          if(this.selectedImage.cropped.height != height) {
            heightCropped = (height * this.selectedImage.cropped.height) / this.selectedImage.height;
          }

          if(this.selectedImage.cropped.width != width) {
            widthCropped = (width * this.selectedImage.cropped.width) / this.selectedImage.width;
          }

          if(this.selectedImage.cropped.x != 0) {
            xCropped = (width * this.selectedImage.cropped.x) / this.selectedImage.width;
          }

          if(this.selectedImage.cropped.y != 0) {
            yCropped = (height * this.selectedImage.cropped.y) / this.selectedImage.height;
          }

          cropped = {
            x: xCropped,
            y: yCropped,
            width: widthCropped, //this.selectedImage.cropped.width,
            height: heightCropped, //this.selectedImage.cropped.height
          };
        }

        if (
          this.selectedImage.typeItem !== "text" &&
          (width <= minWidth || height <= minHeight)
        ) return;

        this.prevMouseMovement.x = event.clientX;
        this.prevMouseMovement.y = event.clientY;
        // if (this.selectedImage.typeItem === "text" && this.selectedCorner) {
        //   const scale = {
        //     x: width / this.selectedImage.original.width,
        //     y: height / this.selectedImage.original.height,
        //   };
        //   this.$store.dispatch("post/textControls/CHANGE_SIZE_IN_TEXT", {
        //     uuid: this.selected,
        //     scale,
        //   });
        // }

        // LÍNEAS GUÍAS
        this.$store.dispatch('post/guideLines/ACTIVE', false);
        this.$store.dispatch('post/guideLines/ACTIVE_HORIZONTAL', false);
        this.$store.dispatch('post/guideLines/ACTIVE_VERTICAL', false);
        this.$store.dispatch('post/guideLines/ACTIVE_SECONDARY', [])

        const xCenter = (this.currentZoom.width - width) / 2;
        const yCenter = (this.currentZoom.height - height) / 2;
        
        if(this.position.x >= xCenter - 6 && this.position.x < xCenter + 6) {
          this.position.x = xCenter;
          this.$store.dispatch('post/guideLines/ACTIVE', true);
          this.$store.dispatch('post/guideLines/ACTIVE_VERTICAL', true);
        }
        
        if(this.position.y >= yCenter - 6 && this.position.y < yCenter + 6) {
          this.position.y = yCenter;
          this.$store.dispatch('post/guideLines/ACTIVE', true);
          this.$store.dispatch('post/guideLines/ACTIVE_HORIZONTAL', true);
        }

        const guideLines = []

        this.images.map(item => {
          if(this.selected !== item.uuid) {
            // LINEA HORIZONTAL
            if(this.position.y >= item.y - 4 && this.position.y < item.y + 4) { // ALINEAR LA PARTE SUPERIOR - SUPERIOR
              if(this.position.x < item.x) {
                guideLines.push({
                  x: this.position.x + width,
                  y: item.y,
                  width: (item.x + item.width) - (this.position.x + width),
                  height: 1
                })
              } else {
                guideLines.push({
                  x: item.x,
                  y: item.y,
                  width: this.position.x - item.x,
                  height: 1
                })
              }
              this.position.y = item.y;
              this.$store.dispatch('post/guideLines/ACTIVE', true);
            }

            const checkHeight =  item.y + item.height - 1
            if(this.position.y >= checkHeight - 4 && this.position.y < checkHeight + 4) { // ALINEAR LA PARTE SUPERIOR - INFERIOR
              if(this.position.x < item.x) {
                guideLines.push({
                  x: this.position.x + width,
                  y: checkHeight,
                  width: (item.x + item.width) - (this.position.x + width),
                  height: 1
                })
              } else {
                guideLines.push({
                  x: item.x,
                  y: checkHeight,
                  width: this.position.x - item.x,
                  height: 1
                })
              }
                this.position.y = checkHeight;
                this.$store.dispatch('post/guideLines/ACTIVE', true);
            }

            if(this.position.y + height >= item.y - 4 && this.position.y + height < item.y + 4) { // ALINEAR LA PARTE INFERIOR - SUPERIOR
              if(this.position.x < item.x) {
                guideLines.push({
                  x: this.position.x + width,
                  y: item.y,
                  width: (item.x + item.width) - (this.position.x + width),
                  height: 1
                })
              } else {
                guideLines.push({
                  x: item.x,
                  y: item.y,
                  width: this.position.x - item.x,
                  height: 1
                })
              }
              this.position.y = item.y - height;
              this.$store.dispatch('post/guideLines/ACTIVE', true);
            }

            if(this.position.y + height >= checkHeight - 4 && this.position.y + height < checkHeight + 4) { // ALINEAR LA PARTE INFERIOR - INFERIOR
              if(this.position.x < item.x) {
                guideLines.push({
                  x: this.position.x + width,
                  y: checkHeight,
                  width: (item.x + item.width) - (this.position.x + width),
                  height: 1
                })
              } else {
                guideLines.push({
                  x: item.x,
                  y: checkHeight,
                  width: this.position.x - item.x,
                  height: 1
                })
              }
              this.position.y = checkHeight - height;
              this.$store.dispatch('post/guideLines/ACTIVE', true);
            }
            // LINEA VERTICAL
            if(this.position.x >= item.x - 4 && this.position.x < item.x + 4) {
              if(this.position.y < item.y) {
                guideLines.push({
                  x: item.x,
                  y: this.position.y + height,
                  width: 1,
                  height: (item.y + item.height) - (this.position.y + height)
                })
              } else {
                guideLines.push({
                  x: item.x,
                  y: item.y,
                  width: 1,
                  height: this.position.y - item.y
                })
              }
              this.position.x = item.x;

              this.$store.dispatch('post/guideLines/ACTIVE', true);
            }

            const checkWidth =  item.x + item.width - 1
            if(this.position.x >= checkWidth - 4 && this.position.x < checkWidth + 4) {
              if(this.position.y < item.y) {
                guideLines.push({
                  x: checkWidth,
                  y: this.position.y + height,
                  width: 1,
                  height: (item.y + item.height) - (this.position.y + height)
                })
              } else {
                guideLines.push({
                  x: checkWidth,
                  y: item.y,
                  width: 1,
                  height: this.position.y - item.y
                })
              }
              this.position.x = checkWidth;
              this.$store.dispatch('post/guideLines/ACTIVE', true);
            }

            if(this.position.x + width >= item.x - 4 && this.position.x + width < item.x + 4) {
              if(this.position.y < item.y) {
                guideLines.push({
                  x: item.x,
                  y: this.position.y + height,
                  width: 1,
                  height: (item.y + item.height) - (this.position.y + height)
                })
              } else {
                guideLines.push({
                  x: item.x,
                  y: item.y,
                  width: 1,
                  height: this.position.y - item.y
                })
              }
              this.position.x = item.x - width;

              this.$store.dispatch('post/guideLines/ACTIVE', true);
            }

            if(this.position.x + width >= checkWidth - 4 && this.position.x + width < checkWidth + 4) {
              if(this.position.y < item.y) {
                guideLines.push({
                  x: checkWidth,
                  y: this.position.y + height,
                  width: 1,
                  height: (item.y + item.height) - (this.position.y + height)
                })
              } else {
                guideLines.push({
                  x: checkWidth,
                  y: item.y,
                  width: 1,
                  height: this.position.y - item.y
                })
              }
              this.position.x = checkWidth - width;

              this.$store.dispatch('post/guideLines/ACTIVE', true);
            }
          }
        });

        this.$store.dispatch('post/guideLines/ACTIVE_SECONDARY', guideLines)

        // CIERRE LINEAS GUIAS 
        this.$store.dispatch("post/drag/UPDATE_STYLES_IN_SELECTION_RESIZE", {
          width,
          height,
          transform: {
            translate: {
              x: this.position.x,
              y: this.position.y,
            },
          },
        });

        this.$store.dispatch("post/drag/RESIZE", {
          uuid: this.selected,
          width,
          height,
          x: this.position.x,
          y: this.position.y,
          rotate: this.selectedStylesResize.transform.rotate,
          cropped,
        });
      }
      
    },
    onMouseUp() {
      // console.log('ennnnnd drag');
      this.$store.dispatch('post/guideLines/ACTIVE', false);
      this.$store.dispatch('post/drag/ADD_IS_DRAGGING', false);
      
      let valCount = this.counterClick;
      valCount++
      this.$store.dispatch('post/drag/TEXT_CLICK_COUNT', valCount);
      if (this.selectedImage.typeItem == "text") {
        // console.log('un click 1', this.countClick, this.counterClick);

        this.zIndexQuillWrap('0');
        if(this.quillEditor == null) this.$store.dispatch('post/drag/UPDATE_STYLES_IN_SELECTED_TEXT', this.selectedImage);
        this.$store.dispatch("post/drag/UPDATE_IS_EDITING_TEXT_BY_UUID", { uuidd : this.selectedImage.uuid , value: true });
        this.isTextResize = false;
        this.quillEditor.setSelection(null);
        // this.quillEditor.setSelection(0, 4);
        if (this.counterClick === 2) {
          // console.log('un click 2', this.countClick,this.counterClick);
          
          this.$store.dispatch('post/drag/ACTIVE_TO_TEXTEDIT', true);
          this.$store.dispatch("post/canvas/IS_TEXT_SELECTED", false);
          this.zIndexQuillWrap('999');
          this.$store.dispatch('post/drag/TEXT_CLICK_COUNT', 0);
          let lengthQuillText = this.quillEditor.getLength();
          this.quillEditor.setSelection(0, lengthQuillText);
          // console.log('dos', this.quillEditor.getLength());
        }
        const height = this.selectedStylesResize.height;
        const index = this.texts.findIndex(
          ({ uuid }) => uuid == this.selectedImage.uuid
        );
        const divtext = document.getElementById(`textedit${this.selectedImage.indexPosition}`);
        if (divtext.clientHeight > height) {
          this.$store.dispatch("post/drag/UPDATE_TEXT_HEIGHT_BY_INDEX", {
            defaultHeight: divtext.clientHeight,
            uuid: this.selectedImage.uuid,
            index: 0,
          });
          this.$store.dispatch(
            "post/drag/UPDATE_HEIGHT_STYLES_IN_SELECTION_RESIZE",
            divtext.clientHeight
          );
        }
      }else{
        this.$store.dispatch("post/drag/CHANGE_IS_OPEN_MENU_FONTS", false);
        this.$store.dispatch('post/drag/DISABLED_ALL_EDITING_TEXT');
        this.$store.dispatch('post/drag/TEXT_CLICK_COUNT', 0);
        if (this.quillEditor != null) this.$store.dispatch('post/drag/DESTROY_QUILL');
      }
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
      this.clicked = false;
      window.removeEventListener("pointermove", this.onMouseMove);
      window.removeEventListener("pointerup", this.onMouseUp);
    },
    zIndexQuillWrap(val){
      let quillWrap = document.getElementById('wrapQuill');
      quillWrap.style.zIndex = val;
      // console.log('quill up div', val);
    },
    onMouseOut() {
      this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', null);
    },
    onClick() {
      console.log('init click');
      this.$store.dispatch("post/drag/CHANGE_IS_OPEN_MENU_FONTS", false);
      this.$store.dispatch('post/drag/ACTIVE_TO_TEXTEDIT', false);
      this.$store.dispatch('post/drag/DESTROY_QUILL');
      this.$store.dispatch('post/drag/DISABLED_ALL_EDITING_TEXT');
      this.$store.dispatch("post/drag/UPDATE_STYLES_IN_SELECTION_RESIZE", {
        width: this.isHoverStyles.width,
        height: this.isHoverStyles.height,
        transform: {
          translate: {
            x: this.isHoverStyles.transform.translate.x,
            y: this.isHoverStyles.transform.translate.y,
          },
          rotate: this.isHoverStyles.transform.rotate
        },
      });
      
      this.$store.dispatch('post/image/UPDATE_IMAGE_SELECTED', this.isHoverStyles.uuid);

      this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', null);
    },
    movePosition(event) {
      this.selectedCorner = null;
      this.clicked = true;
      this.prevMouseMovement.x = event.clientX;
      this.prevMouseMovement.y = event.clientY;
      this.position = {
        x: this.selectedStylesResize.transform.translate.x,
        y: this.selectedStylesResize.transform.translate.y,
      };

      window.addEventListener("pointermove", this.onMouseMove);
      window.addEventListener("pointerup", this.onMouseUp);
    },
    rotation() {
      const box = this.$refs.resizeOut;
      let boxBoundingRect = box.getBoundingClientRect();
      this.boxCenter = {
        x: boxBoundingRect.left + boxBoundingRect.width / 2,
        y: boxBoundingRect.top + boxBoundingRect.height / 2,
      };
      window.addEventListener("pointermove", this.rotationMove);
      window.addEventListener("pointerup", this.rotationEnd);
    },
    rotationMove(event) {
      if(this.selectedImage.typeItem === 'text'){
        let elementTextToHide = document.getElementById(`textedit${this.selectedImage.indexPosition}`);
        elementTextToHide.classList.add('opacity_0');
      }
      let angle =
      Math.atan2(
        event.pageX - this.boxCenter.x,
        -(event.pageY - this.boxCenter.y)
      ) *
      (180 / Math.PI) -
      180;
      
      if (angle * -1 < 5 || angle * -1 > 355) angle = 0;

      this.$store.dispatch("post/drag/EVERY_ROTATE", {
        uuid: this.selected,
        rotate: angle,
      });
      this.rotate = angle;
      this.$store.dispatch("post/drag/UPDATE_ROTATE", angle);
      this.$store.dispatch("post/image/UPDATE_IMAGE_SELECTED", this.selected);
      this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', null);
      
    },
    rotationEnd() {
      this.$store.dispatch("post/drag/UPDATE_IS_EDITING_TEXT_BY_UUID", { uuidd : this.selectedImage.uuid , value: true });
      this.$store.dispatch("post/drag/ROTATE", {
        uuid: this.selected,
        rotate: this.rotate,
      });
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
      window.removeEventListener("pointermove", this.rotationMove);
      window.removeEventListener("pointerup", this.rotationEnd);
    },
  },
};
</script>

<style lang="scss" scoped>
.pref{
  // z-index: 9999 !important;
}
.post-content {
  &__rotate {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    color: #00a5ff;
    background-color: #fff;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      height: 18px;
      width: 3px;
      border-left: 1px dashed #00a6ff;
      top: -20px;
    }
  }

  &__resize {
    &-tools {
      transition: ease all;
      position: absolute;
    }
    &-box {
      border: 1.5px solid #00a5ff;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 10;
      cursor: move;
    }

    
    &-tl,
    &-tr,
    &-bl,
    &-br {
      height: 10px;
      width: 10px;
      background-color: #00a5ff;
      position: absolute;

      z-index: 20;
    }
    &-tl {
      left: -5px;
      top: -5px;
      cursor: nw-resize;
    }

    &-tr {
      right: -5px;
      top: -5px;
      cursor: ne-resize;
    }

    &-bl {
      bottom: -5px;
      left: -5px;
      cursor: ne-resize;
    }

    &-br {
      bottom: -5px;
      right: -5px;
      cursor: nw-resize;
    }
    &-cl,
    &-cr {
      top: 50%;
      cursor: nw-resize;
      margin-top: -5px;
      height: 10px;
      width: 5px;
      background-color: #00a5ff;
      position: absolute;
    }
    &-cr {
      right: -3px;
      cursor: w-resize;
    }
    &-cl {
      left: -3px;
      cursor: w-resize;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  #wrapQuill ::v-deep(.ql-editor){
      padding: 0 !important;
  }
}
</style>