
<template>
  <div v-if="!isLoading && saved.items.length === 0" class="loader-container">
    <div class="loader-container__progress">
      <div class="loader-container__progress--text">
        Sorry, we couldn't find any saved items
      </div>
    </div>
  </div>
  <div 
    v-else
    class="wrapper-scroll scroll-base container_image_scroll"
    v-on:scroll="handleScroll"
    ref="container"
  >
    <div
      class="img_wrapper saved-item"
      v-for="savePost in saved.items"
      :key="savePost.uuid"
      @click="chooseSaved(savePost)"
    >
      <v-img
        :src="savePost.finalImage"
        :alt="savePost.name"
        aspect-ratio="1"
        class="ma-1"
        style="cursor: pointer"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <template v-if="isLoading">
      <v-skeleton-loader
        class="img_wrapper ma-1"
        width="146"
        height="146"
        type="image"
        v-for="i in take"
        :key="i"
        dark
        tile
      ></v-skeleton-loader>
    </template>
  </div>
</template>
<script>
import { mapGetters } from '@design/styleguide';
const idClientStorage = sessionStorage.getItem("client_id");
const clientId = Number(idClientStorage || process.env.VUE_APP_ID);
export default {
  components: {},
  name: "PostSaved",
  data() {
    return {
      take: 10,
      page: 0,
      addSavedPosts: [],
      loadingScroll: false,
      firstAmountOfSaved: 0,
      isSavedPosts: false,
      currentSaved: []
    };
  },
  mounted() {
      this.loadMoreItems()
      this.$store.commit("post/saved/SET_ACTIVE", true);
  },
  computed: {
    ...mapGetters({
      cleanPosts: 'post/saved/cleanSavedPost',
      isLoading: 'post/saved/isLoading'
    }),
    saved() {
      const saved = this.$store.getters["post/saved/savedAll"]      
      this.currentSaved.push(...saved)
      return {
        scrollable: saved.length ? true : false,
        items: this.currentSaved,
        hasMore: saved.length ? true : false
      }
    }
  },
  watch: {
    cleanPosts(val) {
      if (val) this.addSavedPosts = [];
    },
  },
  methods: {
    handleScroll() {
      const container = this.$refs.container;
      const lastChild = container.querySelector('.saved-item:last-of-type')

      if(!lastChild) return

      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  this.loadMoreItems();
              }
            });
          },
          {
            root: null,
            threshold: 0.9, 
          }
        );
        observer.observe(lastChild);
    },
    async loadMoreItems() {
      if(!this.isLoading && this.saved.hasMore || this.page === 0) {  

          this.page++;
          await this.$store.dispatch("post/saved/FIND", {
            take: this.take,
            page: this.page,
            clientId
          });

        const container = this.$refs.container;
        
        if(!container) return

        if(container.scrollHeight <= container.clientHeight) this.loadMoreItems()
      }
    },
    validationMoreSaved() {
      if (this.firstAmountOfSaved === 0) {
        this.firstAmountOfSaved = this.addSavedPosts.length;
        return true;
      }
      if (this.firstAmountOfSaved < this.addSavedPosts.length) {
        this.firstAmountOfSaved = this.addSavedPosts.length;
        return true;
      } else return false;
    },
    chooseSaved(savePost) {
      this.$store.dispatch("post/main/LOAD_POST_CANVAS", savePost);
    },
  },
  destroyed() {
    this.$store.commit("post/saved/SET_CLEAN_POSTS", false);
    this.$store.commit("post/saved/SET_ACTIVE", false);
  },
};
</script>

<style lang="scss" scoped>
.img_wrapper {
  width: 154px;
  height: 154px;
  display: inline-block;
  overflow: hidden;
  img {
    margin: 4px;
    cursor: pointer;
  }
}
.margin-1rem {
  margin: 1rem 0.6rem;
}
</style>
