import { apollo } from "../../../../plugins/vue-apollo";
import { CREATE_POST, UPDATE_POST } from "../graphql/mutation";
import { FIND_ALl_SAVED } from "../graphql/query";
import { messagePopUp } from "../../../../utils/utils";
export default {
  /* 
		Note: find post by latest and folders to addon module 	
	*/
  FIND: async ({ commit }, { take, page, clientId }) => {
    commit('SET_IS_LOADING', true)
    await apollo
      .query({
        query: FIND_ALl_SAVED,
        variables: { take, page, clientId },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { savedAll } }) => {
        commit("SET_SAVED_ALL", savedAll);
        commit('SET_IS_LOADING', false)
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  /* FIND_WITH_KEYWORDS: ({ commit, dispatch }, { keywords }) => {
    apollo
      .query({
        query: FIND_TEMPLATES_WITH_KEYWORDS,
        variables: { keywords },
      })
      .then(({ data: { templatesByKeywords } }) => {
        commit("SET_TEMPLATES_BY_KEYWORD", templatesByKeywords);
				
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  }, */
  /*
   *Function create a post by user
   *@param {post} Object
   *@return {Object{}} post
   */
  CREATE: async ({ commit, dispatch, getters }, { post, responseFunc }) => {
    const clientId = post.clientId;
    await apollo
      .mutate({
        mutation: CREATE_POST,
        variables: { post },
      })
      .then(({ data: { saved } }) => {
        console.log("getters ", getters);
        commit("post/main/SET_ID_POST", saved.id, { root: true });
        commit("post/main/SET_UUID_POST", saved.uuid, { root: true });
        commit(
          "global/saved/SET_LOADING",
          { load: false, status: true },
          { root: true }
        );
        commit("post/saved/RESET_SAVED_POST", {}, { root: true });
        commit("SET_CLEAN_POSTS", true);
        if (getters.active) {
          console.log(" active ");
          dispatch(
            "post/saved/FIND",
            { take: 12, page: 1, clientId },
            { root: true }
          );
        }
      })
      .catch((error) => {
        console.log("[Error] in GraphQl CREATE (Post)", error.message);
        commit(
          "global/saved/SET_LOADING",
          { load: false, status: false },
          { root: true }
        );
        const msgResp = error?.message  ?? "Server fails to create a post."; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  UPDATE: async ({ commit }, { id, post, responseFunc }) => {
    await apollo
      .mutate({
        mutation: UPDATE_POST,
        variables: { id, post },
      })
      .then(() => {
        commit(
          "global/saved/SET_LOADING",
          { load: false, status: true },
          { root: true }
        );
      })
      .catch((error) => {
        console.log("[Error] in GraphQl UPDATE (Post)", error.message);
        commit(
          "global/saved/SET_LOADING",
          { load: false, status: false },
          { root: true }
        );
        const msgResp = error?.message  ?? "Server fails to update a post."; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  UPDATE_NAME: (_, { id, post, responseFunc }) => {
    apollo
      .mutate({
        mutation: UPDATE_POST,
        variables: { id, post },
      })
      .then(() => {
        const msgResp =  "Post's name updated successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl UPDATE_NAME", error);
        const msgResp = error?.message ?? "Server fails to update Post's name." ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
};
