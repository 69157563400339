import { apollo } from "../../../../plugins/vue-apollo";
import { messagePopUp } from "@design/styleguide";
import { CREATE_UPLOAD, DELETE_UPLOAD } from "../graphql/mutation";
import {
  FIND_UPLOADS,
  FIND_WITH_KEYWORDS,
  FIND_POSTS_BY_FOLDER_ID,
  FIND_UPLOADS_BY_KEYWORDS,
} from "../graphql/query";
import {
  uploadFile,
  uploadImage,
  getSignedUrl,
  getSignedUrlPic,
  uploadFileBySignedUrlS3,
  uploadFileToS3,
  processUploadThumbnailsImage,
} from "../service";

export default {
  /* 
		Note: use find uploads to addon module.	
	*/
  FIND: async ({ commit }, { take, page, clientId, type }) => {
    commit('SET_IS_LOADING', true)
    await apollo
      .query({
        query: FIND_UPLOADS,
        fetchPolicy: "no-cache",
        variables: { take, page, clientId, type },
      })
      .then(({ data: { uploads } }) => {
        commit("SET_UPLOADS", uploads);
        commit('SET_IS_LOADING', false)
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  FIND_WITH_KEYWORDS: ({ commit }, keyword) => {
    apollo
      .query({
        query: FIND_WITH_KEYWORDS,
        variables: { keyword },
      })
      .then(({ data: { imagesByKeywords } }) => {
        commit("SET_IMAGE_BYKEYWORD", imagesByKeywords);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  CREATE: async ({ commit }, { upload, object }) => {
    //const clientId = object.clientId;
    return await apollo
      .mutate({
        mutation: CREATE_UPLOAD,
        variables: {
          upload,
          object,
        },
        update: (cache, { data: { upload } }) => {
          //* no cache, because it is a dynamic search uploads.
          /* const data = cache.readQuery({
            query: FIND_UPLOADS,
            variables: { take, page,  clientId, type: 'image' },
          });
          data.uploads.push(upload);

          cache.writeQuery({
            query: FIND_UPLOADS,
            variables: { take, page,  clientId, type:'image' },
            data,
          }); */
          commit("SET_UPLOADS", [upload]);
        },
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  //*Isc_post
  FIND_POSTS_BY_FOLDER: ({ commit }, { page, take, folderId, clientId }) => {
    apollo
      .query({
        query: FIND_POSTS_BY_FOLDER_ID,
        variables: { page, take, folderId, clientId },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { folders } }) => {
        commit("SET_POSTS", folders);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  //* Upload File
  /* UPLOAD_FILE: async (
    { dispatch },
    { formData, index, clientId, fileSizeKb }
  ) => {
    return await uploadFile(formData, dispatch, index, clientId, fileSizeKb);
  }, */
  //*===== UPLOAD SINGLE IMAGE (Using for screenshot)=====
  UPLOAD_SINGLE_IMAGE: async (_, { fileKey, contentType }) => {
    return await uploadImage({ fileKey, contentType });
  },
  //* Sign to AWS, no auth required.
  GET_SIGNED_URL_PIC: async (_, { payload }) => {
    return await getSignedUrlPic(payload);
  },
  //*After signing to AWS, send the file to S3 
 // This function does not have UPLOAD_PROGRESS
  UPLOAD_SIGNED_URL_TO_S3: async ( _, { s3Url, file }) => {
    return await uploadFileToS3(s3Url, file);
  },
//*===== ACTIONS FOR UPLOAD PIC BY CLIENT (Module Upload ) =====
  GET_SIGNED_URL_S3: async ({ dispatch }, { payload, clientId, sizeKb, index }) => {
    return await getSignedUrl(payload, clientId, sizeKb, index, dispatch);
  },
  //* IMPORTANT:  Upload a file with url which AWS gives us 
  UPLOAD_URL_SIGNED_S3: async ( { dispatch }, { s3Url, file, index }) => {
    return await uploadFileBySignedUrlS3(s3Url, file, index, dispatch);
  },
  GET_PROCESS_THUMBNAILS_IMAGE: async ({ dispatch }, { fileKey, contentType, index }) => {
    return await processUploadThumbnailsImage({ fileKey, contentType, index, dispatch })
  },
  //* === END UPLOAD FILES ====
  FILES_PROGRESS: ({ commit }, { files }) => {
    commit("SET_FILES", files);
  },
  RESET_FILES: ({ commit }, { index }) => {
    commit("RESET_FILES_PROGRESS", index);
  },
  RESET_UPLOADS: ({ commit }) => {
    commit("RESET_UPLOADS");
  },
  UPLOAD_PROGRESS: ({ commit }, { progress, index }) => {
    let data = {
      index,
      progress,
    };
    commit("SET_PROGRESS", data);
  },
  ERROR_UPLOAD: ({ commit }, { error, index, msgError }) => {
    let data = {
      index,
      error,
      msgError,
    };
    commit("SET_ERROR", data);
  },
  DELETE: async ({ commit }, { id, responseFunc }) => {
    return await apollo
      .mutate({
        mutation: DELETE_UPLOAD,
        variables: {
          id,
        },
      })
      .catch((error) => {
        console.error(
          "[Error] in Mutation DELETE (Upload[Styleguide]) ",
          error
        );
        const msgResp = error.message;
        const typeResp = "error";
        return responseFunc(msgResp, typeResp);
      });
  },
  FIND_UPLOADS_BY_KEYWORDS: (
    { commit, dispatch },
    { take, page, keywords, typeUser, clientId, type }
  ) => {
    apollo
      .query({
        query: FIND_UPLOADS_BY_KEYWORDS,
        variables: {
          take,
          page,
          keywords,
          typeUser,
          clientId,
          type,
        },
      })
      .then(({ data: { uploads } }) => {
        commit("SET_UPLOADS_BY_KEYWORDS", uploads);
      })
      .catch((err) => {
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
};
