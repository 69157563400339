<template>
  <div>
    <div class="margin-1rem">
      <div class="text margin-1rem">
        <p
          class="text__title"
          draggable
          @click="addItemText(textsInline.title)"
          :style="textsInline.title.content[0].stylesThumbnail"
        >
          Add Title
        </p>
        <p
          class="text__subtitle"
          @click="addItemText(textsInline.subtitle)"
          :style="textsInline.subtitle.content[0].stylesThumbnail"
        >
          Add Subtitle
        </p>
        <p
          class="text__solo"
          @click="addItemText(textsInline.simpleText)"
          :style="textsInline.simpleText.content[0].stylesThumbnail"
        >
          Add Text
        </p>
      </div>
      <div v-if="texts.length > 0" class="wrapper-text scroll-base" style="">
        <div class="items-content">
          <v-row
            class="row"
            dense
            style="align-items: center; margin: 0"
            >
            <div
              class="text-object"
              v-for="(text, index) in texts"
              :key="text.id"
              draggable
              @click="addItemText(text.value)"
              @dragstart="dragStartText($event, text.value)"
              @dragend="dragEndText"
            >
              <div
                class="words"
                v-for="item in text.value.content"
                :key="item.key"
                :style="item.stylesThumbnail"
              >
              <div class="contentHtml">
                <div class="innerHtml" v-html="item.defaultThumbnail"></div>
                <!-- {{ item.default }} -->
              </div>
            </div>
              </div>
            <!-- </div> -->
          </v-row>
        </div>
      </div>
      <div v-else class="loader-container">
        <div v-if="activeScroll" class="loader-container__progress">
          <div class="loader-container__progress--text">Getting Text Templates</div>
          <v-progress-linear
            color="white"
            height="6"
            indeterminate
            rounded
          ></v-progress-linear>
        </div>
        <div v-else class="loader-container__progress">
          <div class="loader-container__progress--text">
            Sorry, we couldn't find any matching items
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "@design/styleguide";
export default {
  components: {},
  name: "PostText",
  props: {
    route: {
      type: String,
    },
    trigger: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      htmlTest:'<p><span class="ql-font-BebasNeue lineHeight-1-0" style="font-size: 35px">LOREM IPSUM DOLOR SIT AME</span></p><p><span class="ql-font-Arial" style="font-size: 8px"> </span></p><p><span class="ql-font-Arial lineHeight-1-0" style="font-size: 17px">Maecenas maximus sapien vitae diam mollis tempus. Nam a dignis.</span></p>',
      prevItemDrag: null,
      textsInline: {
        title: {
          template: false,
          height: 60,
          width: 230,
          content: [
            {
              defaultThumbnail: "Add Title",
              default:
                '<p class="ql-align-center"><span class="ql-font-Arial" style="font-size: 42px;">Add Title</span></p>',
              delta: [
                {
                  attributes: {
                    size: "42px",
                    font: "Arial",
                  },
                  insert: "Add Title",
                },
                {
                  attributes: {
                    align: "center",
                  },
                  insert: "\n",
                },
              ],
              stylesThumbnail: {
                fontSize: "24px",
                lineHeight: "24px",
                textAlign: "left",
                fontWeight: "bold",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#FFFFFF",
              },
              styles: {
                fontSize: "42px",
                lineHeight: "42px",
                textAlign: "left",
                fontWeight: "regular",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#000000FF",
              },
            },
          ],
        },
        subtitle: {
          height: 47,
          width: 190,
          template: false,
          content: [
            {
              defaultThumbnail: "Add subtitle",
              default:
                '<p class="ql-align-center"><span class="ql-font-Arial" style="font-size: 32px;">Add subtitle</span></p>',
              delta: [
                {
                  attributes: {
                    size: "32px",
                    font: "Arial",
                  },
                  insert: "Add subtitle",
                },
                {
                  attributes: {
                    align: "center",
                  },
                  insert: "\n",
                },
              ],
              stylesThumbnail: {
                fontSize: "17px",
                lineHeight: "17px",
                textAlign: "left",
                fontWeight: "",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#FFFFFF",
              },
              styles: {
                fontSize: "32px",
                lineHeight: "32px",
                textAlign: "left",
                fontWeight: "regular",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#000000FF",
              },
            },
          ],
        },
        simpleText: {
          height: 30,
          width: 120,
          template: false,
          content: [
            {
              defaultThumbnail: "Add Text",
              default:
                '<p class="ql-align-center"><span class="ql-font-Arial" style="font-size: 24px;">Add Text</span></p>',
              delta: [
                {
                  attributes: {
                    size: "24px",
                    font: "Arial",
                  },
                  insert: "Add Title",
                },
                {
                  attributes: {
                    align: "center",
                  },
                  insert: "\n",
                },
              ],
              stylesThumbnail: {
                fontSize: "12px",
                lineHeight: "12px",
                textAlign: "left",
                fontWeight: "",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#FFFFFF",
              },
              styles: {
                fontSize: "24px",
                lineHeight: "24px",
                textAlign: "left",
                fontWeight: "regular",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#000000FF",
              },
            },
          ],
        },
      },
      ediT: true,
      sizeAT: false,
      infos: false,
      showTexts: true,
      activeScroll: true,
      //! IMPORTANT: DO NOT REMOVE THIS COMMENT
      // newTextArray : [
      //   {
      //     height: 185,
      //     width: 356,
      //     template: true,
      //     content: [
      //       {
      //         defaultThumbnail: '<p class="ql-align-center" style="margin-bottom: 0 !important;"><span class="ql-font-Anton" style="font-size: 20px;">FELIZ NAVIDAD</span></p><p class="ql-align-center" style="margin-bottom: 0 !important;"><span class="ql-font-Biko" style="font-size: 7px;">Y PROSPERO AÑO NUEVO</span></p><p class="ql-align-center" style="margin-bottom: 0 !important;"><span class="ql-font-Poppins" style="font-size: 4px;display: block;line-height: normal;padding: 0 5px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</span></p>',
      //         default:
      //           '<p class="ql-align-center" data-font-size="65" data-font-family="Anton" style="font-size: 65px; font-family: Anton;" data-paragraph-id="p-0-0" data-paragraph-index="0"><span class="ql-font-Anton" style="font-size: 65px; color: rgb(0, 0, 0);">FELIZ NAVIDAD</span></p><p class="ql-align-center" data-font-size="20" data-font-family="Biko" style="font-size: 20px; font-family: Biko;" data-paragraph-id="p-0-1" data-paragraph-index="1"><span class="ql-font-Biko" style="font-size: 20px; color: rgb(0, 0, 0);"> Y PROSPERO AÑO NUEVO</span></p><p class="ql-align-center" data-font-size="20" style="font-size: 20px;" data-paragraph-id="p-0-2" data-paragraph-index="2"><br></p><p class="ql-align-center" data-font-size="13" data-font-family="Poppins" style="font-size: 13px; font-family: Poppins;" data-paragraph-id="p-0-3" data-paragraph-index="3"><span class="ql-font-Poppins" style="font-size: 13px; color: rgb(0, 0, 0);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</span></p>',
      //         delta: [
      //             {
      //           "attributes": {
      //               "font": "Anton",
      //               "size": "65px",
      //               "color": "#000000"
      //           },
      //           "insert": "FELIZ NAVIDAD"
      //             },
      //             {
      //           "attributes": {
      //               "align": "center",
      //               "size": "65px"
      //           },
      //           "insert": "\n"
      //             },
      //             {
      //           "attributes": {
      //               "font": "Biko",
      //               "size": "20px",
      //               "color": "#000000"
      //           },
      //           "insert": " Y PROSPERO AÑO NUEVO"
      //             },
      //             {
      //           "attributes": {
      //               "align": "center",
      //               "size": "20px"
      //           },
      //           "insert": "\n\n"
      //             },
      //             {
      //           "attributes": {
      //               "font": "Poppins",
      //               "size": "13px",
      //               "color": "#000000"
      //           },
      //           "insert": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"
      //             },
      //             {
      //           "attributes": {
      //               "align": "center",
      //               "size": "13px"
      //           },
      //           "insert": "\n"
      //             }
      //         ],
      //         stylesThumbnail: {
      //           fontSize: "24px",
      //           lineHeight: "24px",
      //           textAlign: "left",
      //           fontWeight: "bold",
      //           textTransform: "",
      //           fontFamily: "Arial",
      //           fontStyle: "",
      //           color: "#FFFFFF",
      //         },
      //         styles: {
      //           fontSize: "42px",
      //           lineHeight: "42px",
      //           textAlign: "left",
      //           fontWeight: "regular",
      //           textTransform: "",
      //           fontFamily: "Arial",
      //           fontStyle: "",
      //           color: "#000000FF",
      //           fontsList:[
      //           {
      //               "index": 0,
      //               "font": "55px",
      //               "fontFamily": "BebasNeue"
      //           },
      //           {
      //               "index": 1,
      //               "font": "15px",
      //               "fontFamily": "Arial"
      //           },
      //           {
      //               "index": 2,
      //               "font": "25px",
      //               "fontFamily": "Arial"
      //           }
      //         ],
      //         },
      //       },
      //     ],
      //   }
      // ]
    };
  },
  computed: {
    groups() {
      return this.$store.getters["post/texts/find"];
    },
    elements() {
      return this.$store.getters["post/canvas/read"];
    },
    zoom() {
      return this.$store.getters["post/zoom/post"];
    },

    texts() {
      return this.$store.getters["post/texts/texts"];
    },
  },
  watch: {
    trigger(value) {
      if (value) this.activeScroll = true;
      else {
        //this.$store.commit("post/images/RESET_IMAGES_BY_KEYWORDS");
        this.activeScroll = false;
      }
      /* this.addImages = [];
      this.firstAmountOfImages = 0;
      this.page = 1; */
    },
  },
  mounted() {
    this.$store.dispatch("post/texts/FIND");
  },
  methods: {
    ...mapActions({
      set: "post/canvas/SET",
    }),
    dragStartText(event,text){
      let txt = this.returnItemText(text);
      this.$store.commit("post/drag/DRAG_IMAGE_START", txt)
      this.prevItemDrag = text;
    },
    dragEndText(){
      this.prevItemDrag = null;
    },
    addItemText(item) {
      let txt = this.returnItemText(item);
      this.$store.dispatch("post/drag/ADD_TEXT_IN_CANVAS", txt);
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
    returnItemText(item){
      for (const text of item.content) {
        text.rotate = 0;
        text.x = this.zoom.width / 2;
        text.y = this.zoom.height / 2;
        text.height = item.height;
        text.width = item.width;
        text.isTemplate = item.template;
        text.typeItem = 'text';
        return text;
      }

    },
    addSelectedText(item) {
      const x = this.zoom.width / 2;
      let y = this.zoom.height / 2;
      for (const text of item.content) {
        text.rotate = 0;
        text.x = x;
        text.y = y;
        y += text.height;
        this.$store.dispatch("post/drag/ADD_TEXT_IN_CANVAS", text);
      }
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
    addText(item) {
      item.width = this.zoom.width - 40;
      // item.y = this.zoom.height / 2
      this.$store.dispatch("post/drag/ADD_TEXT_IN_CANVAS", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.lienzo_wrap .controles {
  position: fixed;
  border: 2px solid #00a5ff;
  z-index: 1;
  visibility: hidden;
}
.vdr {
  touch-action: none;
  position: absolute;
  box-sizing: border-box;
  border: 2px solid #00a5ff;
  cursor: move;
}
.vdr.inactive {
  border: none !important;
}
.vdr.active::before {
  outline: none !important;
}
#tool .ql-toolbar {
  position: absolute;
  top: 10px;
  width: 600px;
  z-index: 0;
  background: #fff;
}
.ql-toolbar {
  z-index: 600 !important;
}
.ql-toolbar.ql-snow {
  border: none !important;
  display: none;
  z-index: 0;
}
.ql-container.ql-snow {
  /* border:none !important; */
  height: auto !important;
  min-height: auto;
  /* min-height: 65px; */
  border: none !important;
}
.text {
  color: #ffffff;
  &__title {
    cursor: pointer;
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
  }
  &__subtitle {
    cursor: pointer;
    font-size: 17px;
    line-height: 20px;
  }
  &__solo {
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
  }
}

.wrapper-text {
  height: 70vh !important;
  overflow: scroll;
}
.items-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.text-object {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.7rem !important;
  width: 148px;
  height: 148px;
  background-color: #273444;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
  margin: 5px;
  padding: 10px;
  .words {
    padding: 0;
    margin: 0;
    .contentHtml{
      width: 100%;
      p{
        margin: 0;
      }
    }
  }
}
</style>
